import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiEndPoints } from '../shared/config/api-end-points';
import { PagingConstant } from '../shared/constants/constants';
import { UserAccessReportResponse, FilterResponse } from '../models/user-access-report-response';
import { ExportFileType } from '../models/enums/report-type.enum';
import { CustomPricingReportResponse, FilterResponseCustomReport, ProductCustomPricingReportResponse } from '../models/custom-pricing-report-response';
import { DeclarationReportResponse, InsurerFilterResponse } from '../models/declartion-report-responses/declaration-report-response';
import { SubProduct } from '../models/sub-product';
import { LPGDriversClubDeclarationReportResponse } from '../models/declartion-report-responses/lpg-drivers-club-declaration-report-response';
import { FilterBrokerGroupResponse, FilterProductResponse } from '../models/claims-made-report-responses/claims-made-report-base-response';
import { HttpBaseService } from '../shared/services/http-base.service';
import { MLEIReportResponse } from '../models/claims-made-report-responses/mlei-response';
import { HomeEmergencyReportResponse } from '../models/claims-made-report-responses/home-emergency-response';
import { ExcessProtectionReportResponse } from '../models/claims-made-report-responses/excess-protection-response';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  customPricing$: BehaviorSubject<string> = new BehaviorSubject('');
  brokerBranchOptions: { [id: string]: FilterResponseCustomReport[]; } = {};
  brokerGroupOptions: { [id: string]: FilterResponse[]; } = {};
  subProductOptions: { [id: string]: SubProduct[]; } = {};

  constructor(private readonly api: ApiEndPoints, private readonly http: HttpClient, private readonly _httpService: HttpBaseService) { }

  public getLPGDriversClubDeclarationReport(covers: string[], arrInsurerIds: string[],
    dateFrom: string, dateTo: string, isLoadTotal: boolean,
    sortField: string, sortDirection: string,
    pageIndex: number, pageSize: number): Observable<LPGDriversClubDeclarationReportResponse> {
    return this.http.get<LPGDriversClubDeclarationReportResponse>(
      this.api.getLPGDriversClubDeclarationReportWithPaging(covers, arrInsurerIds, dateFrom, dateTo, isLoadTotal, sortField, sortDirection, pageIndex, pageSize)
    )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public getDeclarationReport(covers: string[], arrInsurerIds: string[],
    dateFrom: string, dateTo: string,
    sortField: string, sortDirection: string,
    pageIndex: number, pageSize: number): Observable<DeclarationReportResponse> {
    return this.http.get<DeclarationReportResponse>(
      this.api.getDeclarationReportWithPaging(covers, arrInsurerIds, dateFrom, dateTo, sortField, sortDirection, pageIndex, pageSize)
    )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public exportLPGDriversClubDeclarationReport(covers: string[], insurerIds: string[], dateFrom: string, dateTo: string, providers: string[], productIds: string[], exportFileType: ExportFileType) {
    return this.api.exportLPGDriversClubDeclarationReport(covers, dateFrom, dateTo, providers, insurerIds, productIds, exportFileType)
  }

  public exportDeclarationReport(covers: string[], insurerIds: string[], dateFrom: string, dateTo: string, providers: string[], productIds: string[], exportFileType: ExportFileType) {
    return this.api.exportDeclarationReport(covers, dateFrom, dateTo, providers, insurerIds, productIds, exportFileType)
  }

  public getUserAccessReport(brokerGroupId: string, brokerId: string, brokerBranchId: string,
    noGroup: boolean,
    status: string,
    sortField: string,
    sortDirection: string,
    pageIndex = PagingConstant.pageIndex,
    pageSize = PagingConstant.pageSize): Observable<UserAccessReportResponse> {
    return this.http
      .get<UserAccessReportResponse>(
        this.api.getUserAccessReportWithPaging(
          brokerGroupId,
          brokerId,
          brokerBranchId,
          noGroup,
          status,
          sortField,
          sortDirection,
          pageIndex,
          pageSize
        )
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public getUserAccessReportFilterBrokerGroups(): Observable<FilterResponse[]> {
    return this.http
      .get<FilterResponse[]>(
        this.api.getUserAccessReportFilterBrokerGroups()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public getProviderDeclarations(): Observable<FilterResponse[]> {
    return this.http.get<FilterResponse[]>(this.api.getProviderDeclarations());
  }

  public getInsurerDeclarations(provider: string[]): Observable<InsurerFilterResponse[]> {
    return this.http.get<InsurerFilterResponse[]>(this.api.getInsurerDeclarations(provider));
  }

  public getInsurerProductNames(insurerIds: string[]): Observable<FilterResponse[]> {
    return this.http.get<FilterResponse[]>(this.api.getInsurerProductDeclaration(insurerIds))
  }

  public getSubProductDeclarations(productIds: string[], insurerIds: string[]): Observable<FilterResponse[]> {
    return this.http.get<FilterResponse[]>(this.api.getSubProductDeclaration(productIds, insurerIds));
  }


  public getUserAccessReportFilterBroker(brokerGroupId: string, isGroup: boolean): Observable<FilterResponse[]> {
    return this.http
      .get<FilterResponse[]>(
        this.api.getUserAccessReportFilterBroker(brokerGroupId, isGroup)
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public getUserAccessReportFilterBrokerBranch(brokerId: string, brokerGroupId: string, isGroup: boolean): Observable<FilterResponse[]> {
    return this.http
      .get<FilterResponse[]>(
        this.api.getUserAccessReportFilterBrokerBranch(brokerId, brokerGroupId, isGroup)
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public getBrokerBranchFilterByProductId(productId: string, brokerGroupId: string): Observable<FilterResponseCustomReport[]> {
    if (!productId) {
      return of({} as FilterResponseCustomReport[])
    }
    return this.http
      .get<FilterResponseCustomReport[]>(
        this.api.getBrokerBranchFilterByProductId(productId, brokerGroupId)
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public getProductCustomPricingReport() {
    return this.http
      .get<ProductCustomPricingReportResponse>(
        this.api.getProductCustomPricingReport()
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public getSubProductsCustomPricingReport(productId: string) {
    return this.http
      .get<SubProduct[]>(
        this.api.getSubProductsCustomPricingReport(productId)
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public getCustomPricingReport(productId: string, brokerGroupId: string, brokerBranchIds: string[],
    subProductIds: string[],
    levelOfCoverIds: string[],
    isCustom: boolean,
    sortField: string,
    sortDirection: string,
    pageIndex = PagingConstant.pageIndex,
    pageSize = PagingConstant.pageSize): Observable<CustomPricingReportResponse> {
    return this.http
      .get<CustomPricingReportResponse>(
        this.api.getCustomPricingReport(
          productId,
          brokerGroupId,
          brokerBranchIds,
          subProductIds,
          levelOfCoverIds,
          isCustom,
          sortField,
          sortDirection,
          pageIndex,
          pageSize
        )
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public exportUserAccessReport(brokerGroupId: string, brokerId: string, brokerBranchId: string,
    noGroup: boolean,
    status: string,
    exportFileType: ExportFileType) {
    return this.api.exportUserAccessReport(brokerGroupId,
      brokerId,
      brokerBranchId,
      noGroup,
      status,
      exportFileType);
  }

  public exportCustonPricingReport(productId: string, brokerGroupId: string,
    brokerBranchIds: string[],
    subProductIds: string[],
    levelOfCoverIds: string[],
    isCustom: boolean,
    sortField: string,
    sortDirection: string,
    exportFileType: ExportFileType) {
    return this.api.exportCustomPricingReport(productId,
      brokerGroupId,
      brokerBranchIds,
      subProductIds,
      levelOfCoverIds,
      isCustom,
      sortField,
      sortDirection,
      exportFileType);
  }

  public getBrokerGroupForClaimsMadeReport() {
    return this._httpService
      .getDataAsync<FilterBrokerGroupResponse[]>(
        this.api.getBrokerGroupForClaimsMadeReport()
      );
  }

  public getProductClaimsMadeReport() {
    return this._httpService
      .getDataAsync<FilterProductResponse[]>(
        this.api.getProductClaimsMadeReport()
      );
  }

  public getClaimsMadeReport(productId: string, brokerBranchIds: string[], brokerGroupId: string,
    subProductIds: string[],
    dateFrom:string,
    dateTo:string,
    sortField: string,
    sortDirection: string,
    pageIndex = PagingConstant.pageIndex,
    pageSize = PagingConstant.pageSize) {
    return this._httpService
      .getData<MLEIReportResponse>(
        this.api.getClaimsMadeReport(productId, brokerBranchIds, brokerGroupId, subProductIds, dateFrom, dateTo, sortField, sortDirection, pageIndex, pageSize)
      ) .pipe(
        map((res) => {
          return res;
        })
      );
  }

 }
