<app-loading *ngIf="(loadingCkEditorInFlight$ | async)"></app-loading>
<main fxFlex fxLayout="column" fxLayoutAlign="Stretch">
  <mat-expansion-panel class="mb-expansion mat-elevation-z0" [(expanded)]="panelProductOpenState">
    <mat-expansion-panel-header [collapsedHeight]="customCollapsedHeight">
      <mat-panel-title>
        <span>
          <h2>Product</h2>
        </span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-card appearance="outlined" fxLayout="column" fxLayout.xs="column">
      <mat-card-header fxLayout="column" fxFlex="30" fxFlex.lt-sm="100">
        <mat-card-title>Product Details</mat-card-title>
        <mat-card-subtitle>Maintain product details here.</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content fxLayout="column" fxFlex="100" fxFlex.lt-sm="100" class="product-content">
        <form role="form" [formGroup]="productForm" accept-charset="UTF-8" novalidate>
          <section>
            <mat-form-field class="select-type">
              <mat-label>Select Product Type from dropdown list</mat-label>
              <mat-select data-test="productSourceType" required placeholder="Select Product Type from dropdown list"
                formControlName="productSourceType">
                <mat-option *ngFor="let type of productSourceTypes" [value]="type.value">
                  {{type.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="productSourceType.invalid">Product Type is required</mat-error>
            </mat-form-field>
          </section>
          <section>
            <mat-form-field class="select-type">
              <mat-label>Product Name</mat-label>
              <input required matInput data-test="productName" placeholder="Product Name" formControlName="productName"
              TitleCase maxlength="200">
              <mat-error *ngIf="productName.errors?.required || productName.errors?.whitespace">Product Name is required</mat-error>
            </mat-form-field>


            <mat-form-field class="select-type">
              <mat-label>Product Short Name</mat-label>
              <input required matInput data-test="productShortName" placeholder="Product Short Name"
              oninput="this.value = this.value?.toUpperCase()" formControlName="productShortName" maxlength="5" [readonly]="updateMode">
              <mat-error *ngIf="productShortName.invalid">Product Short Name is required</mat-error>
            </mat-form-field>

            <mat-form-field class="select-type">
              <mat-label>Select status from dropdown list</mat-label>
              <mat-select data-test="productStatus" placeholder="Select status from dropdown list" formControlName="productStatus" required>
                <mat-option *ngFor="let status of Status.values()" [value]="Status[status]">
                  {{status}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="productStatus.invalid">Status is required</mat-error>
            </mat-form-field>

          </section>
          <section class="display-flex">
            <div class="product-icon" *ngIf="productIconUrl">
              <div>
                <span class="product-icon-text">Product Icon *</span>
              </div>
              <div>
                <a href={{productIconUrl}} target="_blank">{{getFileName(productIconUrl)}}</a>
              </div>
            </div>
            <mat-form-field class="select-file">
              <mat-label>Product Icon File</mat-label>
              <input required data-test="productIcon" placeholder="Product Icon File" matInput type="search" readonly
                formControlName="productIcon">
              <mat-icon matSuffix svgIcon="magnify" (click)="file.click()" class="search-button"></mat-icon>
              <mat-error *ngIf="productIcon.invalid">Product Icon is required</mat-error>
            </mat-form-field>
            <input #file type="file" [hidden]="true" accept="image/*" (change)="onSelectFile($event.target.files)">
          </section>

          <section>
            <mat-form-field class="select-type">
              <mat-label>Product Colour</mat-label>
              <input required matInput data-test="productColour" placeholder="Product Colour"
                formControlName="productColour" maxlength="6">
              <span matPrefix class="mat-colour-prefix"># </span>
              <mat-error *ngIf="productColour.invalid">Product Colour is required</mat-error>
            </mat-form-field>
            <mat-form-field class="select-type">
              <mat-label>Order</mat-label>
              <input required matInput type="number" data-test="productOrder" spellcheck="false" min="1" step="1"
                maxlength="2"
                onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode > 47 && event.charCode <= 57"
                placeholder="Order" formControlName="productOrder" (blur)="checkOrderValue()">

              <mat-error *ngIf="productOrder.invalid">Order is required</mat-error>
            </mat-form-field>
          </section>

          <section>
            <mat-form-field class="select-type">
              <mat-label>Premium Nominal Code</mat-label>
              <input required matInput data-test="premiumNominalCode" spellcheck="false" minlength="6" maxlength="10"
                placeholder="Premium Nominal Code" formControlName="premiumNominalCode">
              <mat-error *ngIf="premiumNominalCode.invalid">Premium Nominal Code is required</mat-error>
            </mat-form-field>

            <mat-form-field class="select-type">
              <mat-label>IPT Nominal Code</mat-label>
              <input required matInput data-test="iptNominalCode" spellcheck="false" minlength="6" maxlength="10"
                placeholder="IPT Nominal Code" formControlName="iptNominalCode">
              <mat-error *ngIf="iptNominalCode.invalid">IPT Nominal Code is required</mat-error>
            </mat-form-field>
          </section>

        </form>
        <form role="form" #myForm="ngForm" accept-charset="UTF-8" novalidate>
          <section class="description-editor">
            <div class="product-information">Product Information Page</div>
            <div class="product-information-sub-title">Maintain product information page here.</div>
            <div>
              <ckeditor [(ngModel)]="mycontent" #productDescriptionCkEditor="ngModel" name="productDescriptionCkEditor"
                [config]="ckeConfig" debounce="500" (focus)="onEditorFocus($event)" (paste)="onEditorPaste($event)" (change)="onEditorChange($event)" (ready)="onEditorReady($event)">
              </ckeditor>
            </div>
          </section>
        </form>
      </mat-card-content>

      <mat-card-actions>
        <button mat-button mat-raised-button color="primary" *ngIf="!updateMode" (click)="onSave()"
          [disabled]="!productForm.valid">Save</button>
        <button mat-button mat-raised-button color="primary" [disabled]="disableSave()"
          (click)="onSaveAndClose()">{{updateMode?'Update':'Save & Close'}}</button>
        <button mat-button mat-raised-button color="secondary" (click)="onClose()">Cancel</button>
      </mat-card-actions>
    </mat-card>
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="!hoDesignUser" class="mb-expansion mat-elevation-z0"
    [(expanded)]="panelSubProductOpenState">
    <mat-expansion-panel-header [collapsedHeight]="customCollapsedHeight">
      <mat-panel-title>
        <h2>Sub Product</h2>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-sub-product [product$]="currentProduct$"></app-sub-product>
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="!hoDesignUser" class="mb-expansion mat-elevation-z0"
    [(expanded)]="panelLevelOfCoverOpenState">
    <mat-expansion-panel-header [collapsedHeight]="customCollapsedHeight">
      <mat-panel-title>
        <h2>Levels of Cover</h2>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-levelsofcover-configuration [product$]="currentProduct$" [productId]="productId" [isFromProduct]="true">
    </app-levelsofcover-configuration>
  </mat-expansion-panel>
</main>
