
import { Component, OnInit, AfterViewInit, Input, ViewChild } from '@angular/core';
import { FormBuilder, Validators, AbstractControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import * as moment from 'moment';
import * as _ from "lodash";
import { DEFAULT_EFFECTIVE_TO_DATE, FORMAT_ONLY_DATE, LeiConfig, TitleMessages } from 'src/app/shared/constants/constants';
import { Status } from 'src/app/models/enums/status.enum';
import { Router } from '@angular/router';
import { IptRateSetting } from 'src/app/models/system-setting';
import { tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { onlyNumberValdator, positiveNumberValidator, validateNumber } from 'src/app/validators/only-numbers.validator';

@Component({
  selector: 'app-ipt-rate-setting',
  templateUrl: './ipt-rate-setting.component.html',
  styleUrls: ['./ipt-rate-setting.component.scss']
})

export class IptRateSettingComponent implements OnInit, AfterViewInit {

  form: FormGroup;

  Status = Status;
  updateIptRate: IptRateSetting;
  updateMode: boolean = false;

  displayedColumns: string[] = ['value', 'user', 'dateUpdated', 'effectiveDateFrom', 'effectiveDateTo'];
  displayedHistoryColumns: string[] = ['value', 'changedByUserName', 'changedAt', 'effectiveDateFrom', 'effectiveDateTo', 'isActive'];

  currentSelectedIptRates: IptRateSetting[] = [];

  historyIptRates: IptRateSetting[] = [];

  enterdEffectiveDateValid: boolean = true;

  defaultEffectiveFrom = moment(new Date());
  defaultEffectiveTo = moment(new Date(DEFAULT_EFFECTIVE_TO_DATE));

  searchForm = this.formBuilder.group({
    isIncludedInactive: [false]
  });


  currentIPTRateValue: number = null;
  currentStatusValue: number = null;
  currentEffectDateFromValue: number = null;
  currentEffectDateToValue: number = null;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('formDirective') private formDirective: NgForm;
  constructor(
    private readonly router: Router,
    private readonly _httpService: HttpBaseService,
    private readonly _api: ApiEndPoints,
    public dialogService: DialogService,
    private formBuilder: FormBuilder) {

    this.createForm();
  }

  get isIncludedInactive() {
    return this.searchForm.get('isIncludedInactive') as AbstractControl;
  }

  async ngOnInit() {
    await this.loadIptRates(false);
    await this.loadCurrentIptRate();
  }

  async loadIptRates(isInCludeInactive: boolean) {
    this.historyIptRates = await this._httpService.getDataAsync(this._api.getIptRates(isInCludeInactive));
  }

  async loadCurrentIptRate() {
    this.updateIptRate = null;
    this.value.setValue('', { emitEvent: false });
    this.status.setValue(Status.Active, { emitEvent: false });
    this.effectiveDateFrom.setValue('', { emitEvent: false });
    this.effectiveDateTo.setValue('', { emitEvent: false });
    let dateEffective = this.defaultEffectiveFrom.format(FORMAT_ONLY_DATE);
    let iptRate = await this._httpService.getDataAsync<IptRateSetting>(this._api.getValidIptRate(dateEffective));
    if (iptRate) {
      this.onRowClick(iptRate);
      this.currentSelectedIptRates.push(iptRate);
    }
  }

  createForm() {
    this.form = this.formBuilder.group({

      value: [undefined, [Validators.required, validateNumber, positiveNumberValidator]],
      status: [undefined, [Validators.required]],
      effectiveDateFrom: [undefined, [Validators.required]],
      effectiveDateTo: ['']
    });
  }

  get value() {
    return this.form.get('value') as AbstractControl;
  }

  get effectiveDateFrom() {
    return this.form.get('effectiveDateFrom') as AbstractControl;
  }

  get effectiveDateTo() {
    return this.form.get('effectiveDateTo') as AbstractControl;
  }

  public get status() {
    return this.form.get('status') as AbstractControl;
  }

  ngAfterViewInit() {
    this.effectiveDateFrom.valueChanges
      .pipe(
        debounceTime(LeiConfig.delayBeforeSearchingWithAutoSearchFields),
        distinctUntilChanged()
      ).subscribe(v => {
        if (!this.updateMode && this.effectiveDateFrom.touched) {
          this.validateEffectiveDateFrom(v);
        }
      });

    this.effectiveDateTo.valueChanges
      .pipe(
        debounceTime(LeiConfig.delayBeforeSearchingWithAutoSearchFields),
        distinctUntilChanged()
      ).subscribe(v => {
        if (!this.updateMode && this.effectiveDateTo.touched) {
          this.validateEffectiveDateTo(v);
        }
      });

    this.sort.sortChange
      .pipe(
        tap(() => this.sortIptRates())
      )
      .subscribe();

    this.isIncludedInactive.valueChanges.subscribe(value => {
      this.loadIptRates(value);
    });
  }

  sortIptRates() {
    this.historyIptRates = _.orderBy(this.historyIptRates, this.sort.active, [this.sort.direction === 'asc' ? 'asc' : 'desc']);
  }

  async validateEffectiveDateFrom(enterdDate: any) {

    if (this.value.invalid) {
      this.dialogService.openErrorDialog({ title: TitleMessages.warningTitle, message: 'Enter the new IPT Rate and Effective dates' });
      this.value.markAsTouched();
      return;
    }

    if (this.effectiveDateTo.value) {
      const fromDate = moment(enterdDate).format('YYYY-MM-DD');
      const toDate = moment(this.effectiveDateTo.value).format('YYYY-MM-DD');
      if (fromDate >= toDate) {
        this.dialogService.openErrorDialog({ title: TitleMessages.warningTitle, message: `The effective to date entered is ${fromDate == toDate ? 'same as' : 'less than'} the effective from date entered. Please check and re-enter the date!` });
        return;
      }
    }

    let existedIptRate = _.find(this.historyIptRates, (x) => { return (x.value === this.value.value) });

    if (existedIptRate) {
      const enteredDate = moment(enterdDate, FORMAT_ONLY_DATE);
      const existedDate = moment(existedIptRate.effectiveDateFrom, FORMAT_ONLY_DATE);
      if (enteredDate <= existedDate) {
        this.dialogService.openErrorDialog({ title: TitleMessages.warningTitle, message: 'The effective from date entered conflicts with another record in the system. Please check and re-enter the date!' });
        this.enterdEffectiveDateValid = false;
      }
      else {
        this.enterdEffectiveDateValid = true;
      }
    }
    return;
  }

  async validateEffectiveDateTo(enterdDate: any) {

    let errorMessage = '';
    if (this.effectiveDateFrom.value) {
      if ((moment(this.effectiveDateFrom.value, FORMAT_ONLY_DATE)).isAfter((moment(enterdDate, FORMAT_ONLY_DATE))) && (!((moment(this.effectiveDateFrom.value, FORMAT_ONLY_DATE).isSame(moment(enterdDate, FORMAT_ONLY_DATE)))))) {
        errorMessage = 'The effective to date entered is less than the effective from date entered. Please check and re-enter the date!';
        this.enterdEffectiveDateValid = false;
      }
      else if ((moment(this.effectiveDateFrom.value, FORMAT_ONLY_DATE).isSame(moment(enterdDate, FORMAT_ONLY_DATE)))) {
        errorMessage = 'The effective to date and from date both are same . Please check and re-enter the date!';
        this.enterdEffectiveDateValid = false;
      } else {
        this.enterdEffectiveDateValid = true;
      }
    } else {
      errorMessage = 'Enter the new IPT Rate and Effective dates';
    }

    if (!this.enterdEffectiveDateValid) {
      this.dialogService.openErrorDialog({ title: TitleMessages.warningTitle, message: errorMessage });
    }
  }

  async reloadData() {
    this.currentSelectedIptRates = [];
    await this.loadIptRates(this.isIncludedInactive.value);
    await this.loadCurrentIptRate();
  }

  resetForm() {
    this.value.setValue(this.currentIPTRateValue, { emitEvent: false });
    this.value.disable({ emitEvent: false });
    this.status.setValue(this.currentStatusValue, { emitEvent: false });
    this.effectiveDateFrom.setValue(this.currentEffectDateFromValue, { emitEvent: false });
    this.effectiveDateFrom.disable({ emitEvent: false });
    this.effectiveDateTo.setValue(this.currentEffectDateToValue, { emitEvent: false });
    this.effectiveDateTo.disable({ emitEvent: false });
    this.form.markAsPristine();
  }


  markAsPristine() {
    this.form.markAsPristine();
  }

  markAsDirty() {
    this.form.markAsDirty();
  }


  reset() {
    setTimeout(() => {
      this.updateMode = false;
      this.formDirective.resetForm();

      this.value.enable({ emitEvent: false });
      this.effectiveDateFrom.enable({ emitEvent: false });
      this.effectiveDateTo.enable({ emitEvent: false });

      this.form.markAsUntouched();
      this.form.markAsDirty();
    }, 0);
  }

  async onSave() {
    if (this.updateMode) {
      await this.doUpdateIptRate();
    } else {
      await this.doCreateIptRate();
    }
  }

  async doUpdateIptRate() {
    let iptRate: IptRateSetting = {
      id: this.updateIptRate.id,
      rowVersion: this.updateIptRate.rowVersion,
      value: this.updateIptRate.value,
      effectiveDateFrom: moment(this.updateIptRate.effectiveDateFrom).format(FORMAT_ONLY_DATE),
      effectiveDateTo: moment(this.updateIptRate.effectiveDateTo).format(FORMAT_ONLY_DATE),
      isActive: this.status.value,
      changedByUserName: this.updateIptRate.changedByUserName,
      changedAt: this.updateIptRate.changedAt
    };
    await this._httpService.putDataAsync<IptRateSetting>(this._api.updateIptRateSetting(), iptRate);
    this.dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: 'IPT Rate has been updated successfully' }).subscribe(async result => {
      if (result) {
        await this.reloadData();
      }
    });
    this.currentEffectDateFromValue = iptRate.effectiveDateFrom;
    this.currentEffectDateToValue = iptRate.effectiveDateTo;
    this.currentIPTRateValue = iptRate.value;
    this.currentStatusValue = iptRate.isActive ? Status.Active : Status.Inactive;
    this.form.markAsPristine();
  }

  async doCreateIptRate() {
    let iptRate: IptRateSetting = {
      id: undefined,
      rowVersion: undefined,
      value: this.value.value,
      effectiveDateFrom: moment(this.effectiveDateFrom.value).format(FORMAT_ONLY_DATE),
      effectiveDateTo: moment(this.effectiveDateTo.value ? this.effectiveDateTo.value : this.defaultEffectiveTo).format(FORMAT_ONLY_DATE),
      isActive: this.status.value,
      changedByUserName: undefined,
      changedAt: undefined
    };

    this.dialogService.openConfirmationDialog({ title: TitleMessages.confirmationTitle, message: 'Do you wish to update the Pricing records to reflect the new IPT Rate?' }).subscribe(async result => {

      await this._httpService.postDataAsync<any>(this._api.createIptRateSetting(result), iptRate);

      this.dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: 'IPT Rate has been saved successfully' }).subscribe(async result => {
        if (result) {
          await this.reloadData();
        }
      });
    });

    this.form.markAsPristine();
  }

  onRowClick(row: IptRateSetting) {
    this.updateIptRate = row;
    this.updateMode = true;
    this.bindingDataForUpdate(this.updateIptRate);
  }


  bindingDataForUpdate(iptRate: IptRateSetting) {
    this.value.setValue(iptRate.value, { emitEvent: false });
    this.value.disable();
    this.status.setValue(iptRate.isActive ? Status.Active : Status.Inactive);
    this.effectiveDateFrom.setValue(iptRate.effectiveDateFrom, { emitEvent: false });
    this.effectiveDateFrom.disable();
    this.effectiveDateTo.setValue(iptRate.effectiveDateTo, { emitEvent: false });
    this.effectiveDateTo.disable();

    this.currentEffectDateFromValue = iptRate.effectiveDateFrom;
    this.currentEffectDateToValue = iptRate.effectiveDateTo;
    this.currentIPTRateValue = iptRate.value;
    this.currentStatusValue = iptRate.isActive ? Status.Active : Status.Inactive;
  }

  onCloseDialog(isReload: boolean) {
    this.router.navigate(['app/']);
  }
}


