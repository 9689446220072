import { Component, OnInit, ViewChild, AfterViewInit, Input, Output, EventEmitter, ElementRef, QueryList, ViewChildren } from '@angular/core';
import * as _ from 'lodash';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { PricingItem, BranchPricingItem, PricingBase } from 'src/app/models/pricing-item';
import { LevelOfCover } from 'src/app/models/level-of-cover';
import { FormBuilder, AbstractControl } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { DEFAULT_EFFECTIVE_TO_DATE, FORMAT_ONLY_DATE } from 'src/app/shared/constants/constants';
import { MatSort } from '@angular/material/sort';
import { MatRow } from '@angular/material/table';


@Component({
  selector: 'app-pricing-history',
  templateUrl: './pricing-history.component.html',
  styleUrls: ['./pricing-history.component.scss']
})
export class PricingHistoryComponent implements OnInit, AfterViewInit {


  currentSelectedPricings: PricingItem[] = [];
  currentSelectedBranchPricings: BranchPricingItem[] = [];

  displayedColumns: string[] = ['priceType', 'brokerRate', 'brokerRateIncludeIPT', 'minimumSaleRate', 'maximumRetailRate', 'underwriterRate', 'intermediaryCommission', 'effectiveDateFrom', 'effectiveDateTo', 'changedByUserName', 'changedAt', 'isActive'];

  displayedHistoryColumns: string[] = ['priceType', 'brokerRate', 'brokerRateIncludeIPT', 'minimumSaleRate', 'maximumRetailRate', 'underwriterRate', 'intermediaryCommission', 'effectiveDateFrom', 'effectiveDateTo', 'changedByUserName', 'changedAt', 'isActive'];

  @Input() levelOfCover: LevelOfCover;
  @Input() branchPricingMode: boolean = false;
  @Input() brokerBranchId: string = '';
  @Input() reloadHistory$: BehaviorSubject<boolean>;
  @Input() selectedPricingId: string;
  @Input() isIncludedInactiveFromParent: boolean;

  searchForm = this.formBuilder.group({
    isIncludedInactive: [false]
  });


  defaultEffectiveTo = moment(new Date(DEFAULT_EFFECTIVE_TO_DATE));
  defaultEffectiveFrom = moment(new Date());

  historyPricings: PricingItem[] = [];
  historyBranchPricings: BranchPricingItem[] = [];

  currentPricing: PricingBase;
  updatePricing: PricingBase;
  @Output() selectedPricing = new EventEmitter<PricingBase>();

  doneLoadCurrentPricing: boolean = false;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChildren(MatRow, {read: ElementRef}) rows!: QueryList<ElementRef<HTMLTableRowElement>>;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly _api: ApiEndPoints,
    private readonly _httpService: HttpBaseService) {
  }

  get isIncludedInactive() {
    return this.searchForm.get('isIncludedInactive') as AbstractControl;
  }

  async ngOnInit() {
    if (this.isIncludedInactiveFromParent) {
      this.isIncludedInactive.setValue(this.isIncludedInactiveFromParent);
    }
    await this.loadData(this.levelOfCover.id, this.isIncludedInactive.value);
    if (this.selectedPricingId) {
      if (this.branchPricingMode) {
        let selectedBranchPricing = _.find(this.historyBranchPricings, (x) => { return x.id === this.selectedPricingId })
        if (selectedBranchPricing) {
          this.onRowClick(selectedBranchPricing);
        }
      }
      else {
        let selectedPricing = _.find(this.historyPricings, (x) => { return x.id === this.selectedPricingId });
        if (selectedPricing) {
          this.onRowClick(selectedPricing);
        }
      }
      this.scrollToRowId(this.selectedPricingId);
    }
  }

  async loadCurrentPricing(levelOfCoverId: string, dateEffective: string) {
    this.currentSelectedPricings = [];
    this.doneLoadCurrentPricing = false;
    let pricing = await this._httpService.getDataAsync<PricingItem>(this._api.getCurrentPricingByLevelOfCover(levelOfCoverId, dateEffective));
    if (pricing) {
      this.currentSelectedPricings.push(pricing);
      this.currentPricing = pricing;
    }
    this.doneLoadCurrentPricing = true;
  }

  async loadCurrentBranchPricing(branchId: string, levelOfCoverId: string, dateEffective: string) {
    this.currentSelectedBranchPricings = [];
    this.doneLoadCurrentPricing = false;
    let pricing = await this._httpService.getDataAsync<BranchPricingItem>(this._api.getCurrentPricingByLevelOfCoverForBranch(branchId, levelOfCoverId, dateEffective));
    if (pricing) {
      this.currentSelectedBranchPricings.push(pricing);
      this.currentPricing = pricing;
    }
    this.doneLoadCurrentPricing = true;
  }

  async loadData(levelOfCoverId: string, isIncludedInactive: boolean) {
    if (this.branchPricingMode) {
      this.historyBranchPricings = await this._httpService.getDataAsync<BranchPricingItem[]>(this._api.getHistoryBranchPricingsByLevelOfCoverId(this.brokerBranchId, levelOfCoverId, isIncludedInactive));
      await this.loadCurrentBranchPricing(this.brokerBranchId, levelOfCoverId, this.defaultEffectiveFrom.format(FORMAT_ONLY_DATE));
    } else {
      this.historyPricings = await this._httpService.getDataAsync<PricingItem[]>(this._api.getHistoryPricingsByLevelOfCoverId(levelOfCoverId, isIncludedInactive));
      await this.loadCurrentPricing(levelOfCoverId, this.defaultEffectiveFrom.format(FORMAT_ONLY_DATE));
    }
    this.sortHistoryPricings();
  }

  ngAfterViewInit() {
    this.isIncludedInactive.valueChanges.subscribe(value => {
      this.loadData(this.levelOfCover.id, value);
    });

    this.sort.sortChange
      .pipe(
        tap(() => this.sortHistoryPricings())
      )
      .subscribe();

    this.reloadHistory$.subscribe(x => {
      if (x) {
        this.loadData(this.levelOfCover.id, this.isIncludedInactive.value);
      }
    });
  }

  sortHistoryPricings() {
    if (this.branchPricingMode) {
      this.historyBranchPricings = _.orderBy(this.historyBranchPricings, ['isCustom', this.sort.active], ['desc', this.sort.direction === 'asc' ? 'asc' : 'desc']);
    }
    else {
      this.historyPricings = _.orderBy(this.historyPricings, this.sort.active, [this.sort.direction === 'asc' ? 'asc' : 'desc']);
    }
  }

  onRowClick(row: any) {
    this.updatePricing = row;
    this.selectedPricing.emit(row);
  }

  private scrollToRowId(id: string): void {
    let elem = this.rows.find(row => row.nativeElement.id === id);
    elem?.nativeElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }
}
