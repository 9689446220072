import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SellableLevelOfCover } from 'src/app/models/sellable-level-of-cover';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { MatDialogConfig } from '@angular/material/dialog';
import { IeInlineEditComponent } from 'src/app/shared/components/ie-inline-edit/ie-inline-edit.component';
import { Policy } from 'src/app/models/insurance-policy';
import * as moment from 'moment';
import { MY_FORMATS } from 'src/app/shared/constants/constants';

@Component({
    selector: 'app-level-of-cover-table',
    templateUrl: 'level-of-cover-table.component.html',
    styleUrls: ['./level-of-cover-table.component.scss']
})

export class LevelOfCoverTableComponent implements OnInit {
    @Input()policy: Policy = null;
    @Input() editable: boolean = false;
    @Input() isDisableUpdate: boolean = false;
    @Input() isExpired: boolean = false;
    @Output() grossChange = new EventEmitter();

    editableDisplayedColumns: string[] = ['lovDisplayName', 'brokerNet', 'iptRate',  'gross', 'maximumRetailRate', 'action']; // For expired policy
    displayedColumns: string[] = ['lovDisplayName', 'brokerNet', 'iptRate',  'gross']; // For living policy
    dialogConfig = new MatDialogConfig();
    isIE11: boolean = false;
    editTitle = 'Edit Gross Premium';

    get totalNet() {
        if (this.editable){
            return `£${this.policy.levelOfCovers.reduce<number>((accumulator: number, cover: SellableLevelOfCover) => accumulator + cover.customBrokerNet, 0).toFixed(2)}`;
        } else {
            return `£${this.policy.originalLevelOfCovers.reduce<number>((accumulator: number, cover: SellableLevelOfCover) => accumulator + cover.brokerNet, 0).toFixed(2)}`;
        }
    }

    get totalIPT() {
        if (this.editable){
            return `£${this.policy.levelOfCovers.reduce<number>((accumulator: number, cover: SellableLevelOfCover) => accumulator + parseFloat(cover.totalIPT), 0).toFixed(2)}`;
        } else {
            return `£${this.policy.originalLevelOfCovers.reduce<number>((accumulator: number, cover: SellableLevelOfCover) => accumulator + parseFloat(cover.totalOriginalIPT), 0).toFixed(2)}`;
        }
    }

    get totalGross() {
        if (this.editable){
            return `£${this.policy.levelOfCovers.reduce<number>((accumulator: number, cover: SellableLevelOfCover) => accumulator + cover.customClientGrossPremium, 0).toFixed(2)}`;
        } else {
            return `£${this.policy.originalLevelOfCovers.reduce<number>((accumulator: number, cover: SellableLevelOfCover) => accumulator + cover.clientGrossPremium, 0).toFixed(2)}`;
        }
    }

    get isIE() {
        return this.isIE11;
    }

    get startDate() {
        return moment(this.policy.policyStartDate).format(MY_FORMATS.display.dateInput);
    }

    get expiredDate() {
        return moment(this.policy.policyExpireDate).format(MY_FORMATS.display.dateInput);
    }

    get renewalDate() {
        let renewalDate = new Date(this.policy.policyStartDate);
        return moment(renewalDate).add(1, 'year').format(MY_FORMATS.display.dateInput);
    }

    get renewalExpiredDate() {
        let renewalExpiredDate = new Date(this.policy.policyStartDate);
        return moment(renewalExpiredDate).add(2, 'year').subtract(1, 'day').format(MY_FORMATS.display.dateInput);
    }

    constructor(private _dialogService: DialogService) {
    }

    ngOnInit() {
        this.isIE11 = !!navigator.userAgent.match(/Trident\/7\./);
    }

    update(element: SellableLevelOfCover, value: any) {
        if (value == null) { return; }
        let isChange = false;
        this.policy.levelOfCovers.forEach(x => {
          if (x === element) {
            x.formattedGrossPriceToCustomer = value;
          }
          if (!isChange) {
            isChange = x.clientGrossPremium != x.customClientGrossPremium;
          }
        });
        this.grossChange.emit(isChange);
    }

    openEditPremiumPopup(element: SellableLevelOfCover, event: any) {
        const left = event.clientX - 350;
        const top = event.clientY + 28;
        let dialogConfig = new MatDialogConfig();
        dialogConfig.width = '350px';
        dialogConfig.height = '155px';
        dialogConfig.position = { left: left + 'px', top: top + 'px' };
        dialogConfig.disableClose = true;
        dialogConfig.panelClass = 'nopadding-modalbox';
        this._dialogService
          .openComponentDialog(IeInlineEditComponent, { title: this.editTitle, element }, dialogConfig)
          .subscribe(result => {
            if (result && result.newValue) {
              this.update(element, result.newValue);
            }
          });
      }

    // tslint:disable-next-line: variable-name
    moneytary(number: any) {
        return `£${parseFloat(number).toFixed(2)}`;
    }
}
