import { AuditEntity } from "src/app/models/enums/audit-entity.enum";
import { Roles } from "src/app/models/enums/roles";

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
export const DEFAULT_KINDERTONS_LOGO = 'https://kam-dev.visualstudio.com/65bdd84a-b3c2-48d6-8846-686606ded7d8/_apis/wit/attachments/f334b0a4-8c1e-464c-8998-31b8db7f6de3?fileName=K+Group+logo+H-01.png';
export const BROKER_ID = '00000000-1000-428d-bb20-a628b3e3155e';
export const BROKER_BRANCH_ID = '00000000-2a3c-428d-bb20-c52d63ac421b';
export const DEFAULT_EFFECTIVE_TO_DATE = '2049-12-31 23:59:59';
export const DEFAULT_IPT_RATE = 12;
export const FORMAT_ONLY_DATE = 'YYYY-MM-DD';
export const NO_RESULTS_FOUND = 'No results found';

export const ProductConstant = {
  breakdownCover: 'BDCX',
  excessprotection: 'XSP',
  motorLegalExpenses: 'MOT',
  landlordsLegalExpenses: 'LLEI',
  guaranteedTaxiVehicle: 'GTV',
  taxiClub: 'TXC',
  homeEmergencySolutions: 'EPA',
  commercialLegalExpenses: 'CLEI',
  guaranteedReplacementVehicle: 'GRV',
  driversClub: 'KDC',
  familyLegalExpenses: 'FAM',
  lloydLatchfordDrivingSchoolMotorLegalExpenses: "LLML",
  lloydLatchfordCombinedMotorLegalExpenses: "LLCM",
  howdenMotorLegalExpenses: "HML",
  freewayCompleteCover: "FWCC",
  motorcycleLegalExpenses: "MLEI",
  howdenTaxiCompleteHire: "HTCH",
  mLEIFastTrackLevel1: "MLFT1",
  mLEIFastTrackLevel2: "MLFT2",
};

export const PagingConstant = {
  pageIndex: 0,
  pageSize: 5,
  pageSizeLarger: 10,
  pageSizeOptions: [5, 10, 30],
  pageSizeOptionsLarger: [10, 20, 30]
};

export const StatusCodeConstant = {
  conflict: 409,
  badRequest: 400,
};

export const ErrorText = {
  EffectiveDateError: 'Effective Date Error'
};

export const LeiConfig = {
  delayBeforeSearchingWithAutoSearchFields: 500
};

export const MessageForBroker = {
  messageCreateBroker: 'The Broker has been saved successfully',
  messageCreate: 'The Broker and Broker Branch have been saved successfully',
  messageUpdateWithBranch: 'The Broker and Broker Branch have been updated successfully',
  messageUpdate: 'The broker has been updated successfully',
  messageConflict: 'Original Broker Id must be unique. Please input another original broker Id',
  messageLogout: 'Are you sure you wish to exit the application?'
};

export const TitleMessages = {
  confirmationTitle: 'Confirmation Message',
  successTitle:'Success',
  warningTitle: 'Warning'
}

export const AppConstants = {
  dateConfig: {
    date: 'dd/MM/yyyy',
    dateAndTime: 'dd/MM/yyyy HH:mm:ss'
  },
  delayBefore: 500
};

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

export const UserRoleAuditAssigning = {
  [Roles.HOADMIN]: [AuditEntity.Product, AuditEntity.Broker, AuditEntity["Broker Branch"], AuditEntity["Sub Product"], AuditEntity["Level Of Cover"], AuditEntity.Policy, AuditEntity.Quote, AuditEntity.User],
  [Roles.HOSUPERVISOR]: [AuditEntity.Broker, AuditEntity["Broker Branch"], AuditEntity.Policy, AuditEntity.Quote, AuditEntity.User],
  [Roles.HOSYSADMIN]: [AuditEntity.Product, AuditEntity.Broker, AuditEntity["Broker Branch"], AuditEntity["Sub Product"], AuditEntity["Level Of Cover"], AuditEntity.Policy, AuditEntity.Quote, AuditEntity.User],
}
