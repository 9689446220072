
  <mat-card fxLayout="column" fxLayout.xs="column">
    <mat-card-header fxLayout="column" fxFlex="30" fxFlex.lt-sm="100">
      <mat-card-title><strong>Notifications</strong></mat-card-title>
      <mat-card-subtitle>Manage notifications here</mat-card-subtitle>
      <button mat-button class="btn-table-row btn-create" (click)="createNotificationItem()" matTooltip="Create new Notification">
        <mat-icon>add</mat-icon>
      </button>
    </mat-card-header>
    <mat-card-content class="alert-table-content">
      <div [formGroup]="searchForm" class="search-group">
        <div class="section search">
          <app-shared-search (onSearch)="search()" (onValueChange)="onValueChange($event)" [placeholderText]="'Search by Notification Title or Message'">
          </app-shared-search>
        </div>
        <div class="include-inactive">
          <label id="include-inactive-items" class="label-include"><span><strong>Include Inactive
                Items:</strong></span>
          </label>
          <mat-radio-group formControlName="isIncludedInactive">
            <mat-radio-button data-test="noRadio" value="false" [checked]="includeInactive.value === false">No
            </mat-radio-button>
            <mat-radio-button data-test="yesRadio" value="true" [checked]="includeInactive.value === true">Yes
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="filter-area">
          <mat-form-field class="select-type-status">
            <mat-label>Resend Status</mat-label>
            <mat-select data-test="alert-priority-select" placeholder="Resend Status" formControlName="status">
              <mat-option value=''>All</mat-option>
              <mat-option *ngFor="let priority of Priorites.values()" [value]="Priorites[priority]">
                {{priority}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <table mat-table [dataSource]="dataSource" matSort matSortActive="CreatedOn" matSortDirection="desc"
        matSortDisableClear>
        <ng-container matColumnDef="Title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
          <td mat-cell *matCellDef="let row">{{row.title}}</td>
        </ng-container>
        <ng-container matColumnDef="Message">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Message</th>
          <td mat-cell *matCellDef="let row">{{row.message}}</td>
        </ng-container>
        <ng-container matColumnDef="CreatedOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created On</th>
          <td mat-cell *matCellDef="let row">
            {{row.createdOn | date: 'dd/MM/yyyy' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef>Notification Status</th>
          <td mat-cell *matCellDef="let row">
            <mat-icon *ngIf="row.isActive" class="colour-success" svgIcon="check"></mat-icon>
            <mat-icon *ngIf="!row.isActive" class="colour-danger" svgIcon="close"></mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="InitialType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Initial Status
          </th>
          <td mat-cell *matCellDef="let row">{{row.initialType}}</td>
        </ng-container>
        <ng-container matColumnDef="NumberOfResend">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>No. of Times Resent</th>
          <td mat-cell *matCellDef="let row">{{row.numberOfResend}}</td>
        </ng-container>
        <ng-container matColumnDef="Type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Current Resend Status</th>
          <td mat-cell *matCellDef="let row">{{row.type}}</td>
        </ng-container>

        <ng-container matColumnDef="Resend">
          <th mat-header-cell *matHeaderCellDef>Resend</th>
          <td mat-cell *matCellDef="let row">
            <button mat-mini-fab (click)="resend(row)" [disabled]="!row.isActive">
              <mat-icon *ngIf="row.isActive" svgIcon="send" style="font-weight: bolder; color: white"></mat-icon>
              <mat-icon *ngIf="!row.isActive" svgIcon="send" style="font-weight: bolder"></mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="Archive">
          <th mat-header-cell *matHeaderCellDef>Archive</th>
          <td mat-cell *matCellDef="let row">
            <button mat-mini-fab (click)="archive(row)" [disabled]="!row.isActive">
              <mat-icon *ngIf="row.isActive" svgIcon="archive" style="font-weight: bolder; color: white"></mat-icon>
              <mat-icon *ngIf="!row.isActive" svgIcon="archive" style="font-weight: bolder"></mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <div fxFex fxLayout="row" fxLayoutAlign="space-between">
        <span class="not-found-text">{{notFoundMessage}}</span>
        <mat-paginator [length]="dataSource.totalRecords" [pageIndex]="pagingConstant.pageIndex"
          [pageSize]="pagingConstant.pageSizeLarger" [pageSizeOptions]="pagingConstant.pageSizeOptionsLarger" showFirstLastButtons>
        </mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>

