import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { tap } from 'rxjs';
import { DeclarationLine, ExportDeclarationReportResponse, InsurerFilterResponse } from 'src/app/models/declartion-report-responses/declaration-report-response';
import { ExportFileType } from 'src/app/models/enums/report-type.enum';
import { TransactionType } from 'src/app/models/enums/transaction-type.enum';
import { ReportHistoryService } from 'src/app/services/report-history.service';
import { ReportService } from 'src/app/services/report.service';
import { AppConstants, FORMAT_ONLY_DATE, NO_RESULTS_FOUND, PagingConstant } from 'src/app/shared/constants/constants';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { Utility } from 'src/app/utils/utility';

@Component({
  selector: 'app-arag-declarations-report',
  templateUrl: './arag-declarations-report.component.html',
  styleUrls: ['./arag-declarations-report.component.scss']
})
export class AragDeclarationsReportComponent implements OnInit {

  @Input()
  covers: string[];

  @Input()
  insurers: InsurerFilterResponse[];

  @Input()
  provider: string;

  @Input()
  products: string[];

  @Input()
  dateFrom: Date;

  @Input()
  dateTo: Date;

  @Input()
  sourceDialog: ExportDeclarationReportResponse;

  @Input()
  historyItemId: string;

  transactionType = TransactionType;
  allOptionTxt: string = 'All';
  notfound = '';
  pagingConstant = PagingConstant;
  pageSizeOptions: number[] = PagingConstant.pageSizeOptions;
  totalRows: number = 0;
  searchResults: DeclarationLine[] = [];
  dataSource = new MatTableDataSource<DeclarationLine>([]);
  public appConstants = AppConstants;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = [
    'certificateNumber',
    'aragPolicyNumber',
    'inceptionDate',
    'expiryDate',
    'policyHolder',
    'address',
    'postcode',
    'country',
    'doB',
    'transactionDate',
    'transactionType',
    'cancellationDate',
    'netRate',
    'totalIPT',
    'totalAmountPayable',
    'grossPremium'
  ];

  constructor(
    private readonly _reportService: ReportService,
    private readonly httpService: HttpBaseService,
    private readonly reportHistoryService: ReportHistoryService) {
  }

  get notFoundMessage() {
    return this.notfound;
  }

  ngOnInit(): void {
    if (this.sourceDialog != null) {
      let source = this.sortData(this.sourceDialog.declarationLines, '','asc');
      let cloneArray = source.slice();
      let data = source.splice(0, 10);
      this.sourceDialog.declarationLines = cloneArray;
      this.dataSource = new MatTableDataSource<DeclarationLine>(data);
      this.totalRows = this.sourceDialog.declarationLines.length;
    }
  }

  onSubmit() {
    this.resetTable();
    this.loadData();

  }

  resetTable() {
    this.dataSource = new MatTableDataSource<DeclarationLine>([]);
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = PagingConstant.pageSizeOptions[1];
    this.totalRows = 0;
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.sort.sortChange
      .pipe(
        tap(() => this.loadData())
      )
      .subscribe();

    this.paginator.page
      .pipe(
        tap(() => this.loadData())
      )
      .subscribe();
  }

  checkNotFoundResults(totalRecords) {
    this.notfound = totalRecords === 0 ? NO_RESULTS_FOUND : '';
  }

  getTableStyle() {
    let display = this.totalRows == 0 ? 'none' : 'block';
    return {
      'display': display
    };
  }

  loadData() {
    const pageIndex = this.paginator.pageIndex;
    const pageSize = this.paginator.pageSize;
    const sortField = this.sort.active;
    const sortDirection = this.sort.direction;
    if (this.sourceDialog) {
      let source = this.sortData(this.sourceDialog.declarationLines, sortField, sortDirection);
      let cloneArray = source.slice();
      let data = source.splice(pageIndex * pageSize, pageSize);
      this.sourceDialog.declarationLines = cloneArray;
      this.dataSource = new MatTableDataSource<DeclarationLine>(data);
    }
    else {
      let covers = this.covers.filter((x: string) => x != this.allOptionTxt);
      let arrInsurerIds: string[] = [];
      let ids = this.insurers.map((x: { ids: string[] }) => x.ids);
      ids.forEach((x: string[]) => arrInsurerIds = arrInsurerIds.concat(x));

      if (covers.length == 0) {
        this.totalRows = 0;
        this.checkNotFoundResults(this.totalRows);
      }
      else {
        this._reportService.getDeclarationReport(covers, arrInsurerIds, moment(this.dateFrom).format(FORMAT_ONLY_DATE), moment(this.dateTo).format(FORMAT_ONLY_DATE), sortField, sortDirection, pageIndex, pageSize)
          .subscribe((report) => {
            // this.response = report;
            // this.totalRows = report.total;
            this.searchResults = report.declarationLines;
            this.dataSource = new MatTableDataSource<DeclarationLine>(this.searchResults);
            this.totalRows = report.total;
            this.checkNotFoundResults(this.totalRows);
          })
      }
    }
  }

  export(type: ExportFileType) {
    let url: string = '';
    const fromMonth = Utility.getMonthName(this.dateFrom);
    const toMonth = Utility.getMonthName(this.dateTo);
    const fileName = fromMonth != toMonth 
      ? `DeclarationReport ${fromMonth}_${toMonth}_${new Date().toLocaleDateString("en-UK")}`
      : `DeclarationReport ${toMonth}_${new Date().toLocaleDateString("en-UK")}`;

    if (!this.sourceDialog) {
      let covers = this.covers.filter((x: string) => x != this.allOptionTxt);
      let arrInsurerIds: string[] = [];
      let ids = this.insurers.map((x: { ids: string[] }) => x.ids);
      ids.forEach((x: string[]) => arrInsurerIds = arrInsurerIds.concat(x));
      let providers: string[] = [];
      providers.push(this.provider)
      let arrProductIds = this.products.filter((x: string) => x != this.allOptionTxt);

      if (covers.length > 0) {
        url = this._reportService.exportDeclarationReport(covers, arrInsurerIds, moment(this.dateFrom).format(FORMAT_ONLY_DATE), moment(this.dateTo).format(FORMAT_ONLY_DATE),
          providers, arrProductIds, type);     
      }
    }
    else {
      url = this.reportHistoryService.exportDeclarationReport(this.historyItemId, type);    
    }

    this.httpService.downloadFile(url).subscribe(
      (data: BlobPart) => {
        const blob = new Blob([data], { type: this.getContentType(type) });
        let fileURL = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.style.display = "none";
        link.href = fileURL;
        link.download = fileName;
        link.target = '_blank';
        link.click();

        setTimeout(() => {
          link.remove();
          window.URL.revokeObjectURL(fileURL);
        }, 100);

      }
    );

  }

  sortData(data: DeclarationLine[], sortField: string, sortDirection: string): DeclarationLine[] {
    if (sortDirection == 'asc') {
      switch (sortField) {
        case 'inceptionDate':
          data.sort((a: DeclarationLine, b: DeclarationLine) => {
            return (new Date(a.inceptionDate)).getTime() - (new Date(b.inceptionDate)).getTime()
          });
          break;
        case 'expiryDate':
          data.sort((a: DeclarationLine, b: DeclarationLine) => {
            return (new Date(a.expiryDate)).getTime() - (new Date(b.expiryDate)).getTime()
          });
          break;
        default:
          data.sort((a: DeclarationLine, b: DeclarationLine) => {
            return (new Date(a.inceptionDate)).getTime() - (new Date(b.inceptionDate)).getTime()
          });
      }
    }
    else {
      switch (sortField) {
        case 'inceptionDate':
          data.sort((a: DeclarationLine, b: DeclarationLine) => {
            return (new Date(b.inceptionDate)).getTime() - (new Date(a.inceptionDate)).getTime()
          });
          break;
        case 'expiryDate':
          data.sort((a: DeclarationLine, b: DeclarationLine) => {
            return (new Date(b.expiryDate)).getTime() - (new Date(a.expiryDate)).getTime()
          });
          break;
        default:
          data.sort((a: DeclarationLine, b: DeclarationLine) => {
            return (new Date(b.inceptionDate)).getTime() - (new Date(a.inceptionDate)).getTime()
          });
      }
    }

    return data;
  }

  getContentType(fileType: ExportFileType) {
    switch (fileType) {
      case ExportFileType.Pdf:
        return "application/pdf";

      case ExportFileType.Csv:
        return "text/csv";

      case ExportFileType.Excel:
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      default:
        return '';
    }
  }

  exportExcel() {
    this.export(ExportFileType.Excel);
  }

  exportCsv() {
    this.export(ExportFileType.Csv);
  }

  exportPdf() {
    this.export(ExportFileType.Pdf);
  }
}
