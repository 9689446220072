<main fxFex fxLayout="column" fxLayoutAlign="Stretch">
  <div class="amendments-list">
    <h1>Amendments List</h1>
    <ng-container [formGroup]="inputForm">
      <section class="policy-number">
        <mat-form-field class="date-input">
          <mat-label>Date From</mat-label>
          <input data-test="dateFromDatePicker" [matDatepicker]="dateFromComponent" [required]="true" matNativeControl
            spellcheck="false" matInput placeholder="Date From" formControlName="dateFrom">
          <mat-datepicker-toggle matSuffix [for]="dateFromComponent"></mat-datepicker-toggle>
          <mat-datepicker #dateFromComponent></mat-datepicker>
          <mat-hint>dd/mm/yyyy</mat-hint>
          <mat-error *ngIf="dateFrom.invalid">Date From is required</mat-error>
        </mat-form-field>
        <mat-form-field class="date-input">
          <mat-label>Date To</mat-label>
          <input data-test="dateToDatePicker" [matDatepicker]="dateToComponent" [required]="true" matNativeControl
            spellcheck="false" matInput placeholder="Date To" formControlName="dateTo">
          <mat-datepicker-toggle matSuffix [for]="dateToComponent"></mat-datepicker-toggle>
          <mat-datepicker #dateToComponent></mat-datepicker>
          <mat-hint>dd/mm/yyyy</mat-hint>
          <mat-error *ngIf="dateTo.invalid">Date To is required</mat-error>
        </mat-form-field>
        <button mat-button class="filter-button" (click)="filter()">
          Filter
        </button>
      </section>

    </ng-container>
    <table mat-table [dataSource]="data">
      <!-- Number Column -->
      <ng-container matColumnDef="auditedAt">
        <th mat-header-cell *matHeaderCellDef>Amended On</th>
        <td mat-cell *matCellDef="let row">{{row.auditedAt | date: 'dd/MM/yyyy'}}</td>
      </ng-container>
      <!-- Title Column -->
      <ng-container matColumnDef="reason">
        <th mat-header-cell *matHeaderCellDef>Reason</th>
        <td mat-cell *matCellDef="let row">{{row.reason}}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</main>