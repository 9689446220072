import { SellableLevelOfCover } from './sellable-level-of-cover';

export class Policy {
    company: string;
    title: string;
    firstName: string;
    surname: string;
    productId: string;
    productName: string;
    policyId: string;
    certificateNumber: string;
    levelOfCovers: SellableLevelOfCover[];
    originalLevelOfCovers: SellableLevelOfCover[];
    policyStartDate: Date;
    policyExpireDate: Date;
    cancellationDate: Date;
    isExpired: boolean;
    status: string;
}
