<div class="insurer-popup ">
  <h3 class="title">{{updateMode?'Edit Insurer':'Create Insurer'}}</h3>
  <mat-dialog-content class="mat-dialog-content">
    <form [formGroup]="form">
      <section fxLayout="row">
        <mat-form-field fxFlex="35">
          <mat-label>Select sub product from dropdown</mat-label>
          <mat-select data-test="subProduct" required placeholder="Select sub product from dropdown"
            [disabled]='updateMode' formControlName="subProduct">
            <mat-option *ngFor="let subProduct of subProducts" [value]="subProduct.id">
              {{subProduct.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="subProduct.invalid">Sub Product is required</mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="35">
          <mat-label>Master Certificate</mat-label>
          <input [required]="true" matInput data-test="value" placeholder="Master Certificate"
            formControlName="masterCertificate" maxlength="100">
          <mat-error *ngIf="masterCertificate.errors?.required || masterCertificate.errors?.whitespace">MasterCertificate is required</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="35">
          <mat-label>Scheme Code</mat-label>
          <input matInput data-test="schema-code" placeholder="Scheme Code"
            formControlName="schemeCode" maxlength="100">
        </mat-form-field>
      </section>
      <div formArrayName="insurerForms" class="insurers-form">
        <div *ngFor="let item of subInsurerForms.controls; let i = index;" [formGroupName]="i">
          <section fxLayout="row">
            <mat-form-field [fxFlex]="isMultipleInsurers ? 46 : 66">
              <mat-label>{{isMultipleInsurers?'Insurer ' + (i + 1) + ' Name':'Insurer Name'}}</mat-label>
              <input TitleCase [required]="true" matInput data-test="insurer" placeholder="{{isMultipleInsurers?'Insurer ' + (i + 1) + ' Name':'Insurer Name'}}" formControlName="value"
                maxlength="200">
              <mat-error *ngIf="item.get('value') && item.get('value').errors?.required || item.get('value') && item.get('value').errors?.whitespace">{{isMultipleInsurers?'Insurer ' + (i + 1) + ' is required':'Insurer is required'}}</mat-error>
            </mat-form-field>
            <mat-form-field [fxFlex]="isMultipleInsurers ? 46 : 32" class="provider">
              <mat-label>Provider</mat-label>
              <mat-select placeholder="Provider" formControlName="provider">
                <mat-option *ngFor="let provider of providers.values()" [value]="provider.value">
                  {{provider.description}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="add-btn" *ngIf="isMultipleInsurers" fxFlex="8">
              <!-- Use labels instead buttons to avoid red high light if end-user have not enter infor #11497 -->
              <label class="btn-table-row btn-create" [ngClass]="{'disabled-btn': updateMode}" (click)="addInsurerForm()"  matTooltip="Add"><mat-icon class="icon">add</mat-icon></label>
              <label *ngIf="subInsurerForms.controls.length > 1" class="btn-table-row btn-create btn-del" [ngClass]="{'disabled-btn': updateMode}" (click)="removeInsurerForm(item)"  matTooltip="Remove"><mat-icon class="icon">delete</mat-icon></label>
            </div>
          </section>
          <section fxLayout="row">
            <mat-form-field fxFlex="100">
              <mat-label>{{isMultipleInsurers?'Insurer ' + (i + 1) + ' Address':'Insurer Address'}}</mat-label>
              <input [required]="true" matInput data-test="address" placeholder="{{isMultipleInsurers?'Insurer ' + (i + 1) + ' Address':'Insurer Address'}}"
                formControlName="address" maxlength="500">
              <mat-error *ngIf="item.get('address') && item.get('address').errors?.required || item.get('address') && item.get('address').errors?.whitespace">{{isMultipleInsurers?'Insurer ' + (i + 1) + ' address is required':'Insurer address is required'}}
              </mat-error>
            </mat-form-field>
          </section>
        </div>
      </div>

      <section fxLayout="row">
        <mat-form-field fxFlex="35">
          <mat-label>Effective Date From</mat-label>
          <input data-test="effectiveDateFrom" [matDatepicker]="effectiveDateFromComponent" [required]="true"
           matNativeControl spellcheck="false" matInput placeholder="Effective Date From"
            formControlName="effectiveDateFrom" [min]="minEffectiveFromDate" [readonly]="isEffectiveFromDisabled">
          <mat-datepicker-toggle matSuffix [for]="effectiveDateFromComponent" [disabled]="isEffectiveFromDisabled">
          </mat-datepicker-toggle>
          <mat-datepicker #effectiveDateFromComponent></mat-datepicker>
          <mat-hint>dd/mm/yyyy</mat-hint>
          <mat-error *ngIf="!effectiveDateFrom.errors?.matDatepickerParse && effectiveDateFrom.hasError('required')">Effective date from is required</mat-error>
          <mat-error *ngIf="effectiveDateFrom.hasError('matDatepickerMax') || effectiveDateFrom.hasError('matDatepickerMin')">The date is invalid.</mat-error>
          <mat-error *ngIf="effectiveDateFrom.errors?.matDatepickerParse || effectiveDateFrom.errors?.invalid">The date is invalid.</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="35">
          <mat-label>Effective Date To</mat-label>
          <input data-test="effectiveDateTo" [matDatepicker]="effectiveDateToComponent"
            matNativeControl spellcheck="false" matInput placeholder="Effective Date To"
            formControlName="effectiveDateTo" [min]="minEffectiveToDate">
          <mat-datepicker-toggle matSuffix [for]="effectiveDateToComponent">
          </mat-datepicker-toggle>
          <mat-datepicker #effectiveDateToComponent></mat-datepicker>
          <mat-hint>dd/mm/yyyy</mat-hint>
          <mat-error *ngIf="effectiveDateTo.hasError('matDatepickerMax') || effectiveDateTo.hasError('matDatepickerMin')">The date is invalid.</mat-error>
          <mat-error *ngIf="effectiveDateTo.errors?.matDatepickerParse || effectiveDateTo.errors?.invalid">The date is invalid.</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="35">
          <mat-label>Select status from dropdown</mat-label>
          <mat-select data-test="status" placeholder="Select status from dropdown" formControlName="status" required>
            <mat-option *ngFor="let status of Status.values()" [value]="Status[status]">
              {{status}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="status.invalid">Status is required</mat-error>
        </mat-form-field>
      </section>

    </form>
  </mat-dialog-content>

  <mat-dialog-actions class="justify-items-end button-row">
    <button mat-button mat-raised-button color="primary" [disabled]="!form.valid || form.pristine"
      (click)="onSaveAndClose()">{{updateMode?'Update':'Save'}}</button>
    <button mat-button mat-raised-button color="secondary" (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</div>
