<div class="validation-popup">
  <h2>{{updateMode?'Update Level of Cover Validation': 'Create Level of Cover Validation'}}</h2>
  <mat-dialog-content class="mat-dialog-content">
    <form [formGroup]="levelOfCoverValidationForm" class="level-of-cover-validation-container">
      <section layout="row">
        <mat-form-field class="select-type" fxFlex="20">
          <mat-label>Select product from dropdown</mat-label>
          <mat-select data-test="selectProduct" required placeholder="Select product from dropdown"
            formControlName="product">
            <mat-option *ngFor="let product of products" [value]="product.id">
              {{product.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="product.invalid">Product is required</mat-error>
        </mat-form-field>
        <mat-form-field class="select-type" fxFlex="50">
          <mat-label>Select level of cover from dropdown</mat-label>
          <mat-select data-test="selectLevelOfCover" required placeholder="Select level of cover from dropdown"
            [disabled]="updateMode" formControlName="levelOfCover">
            <mat-option *ngFor="let loC of levelOfCovers" [value]="loC.id">
              {{loC.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="levelOfCover.invalid">Level of cover is required</mat-error>
        </mat-form-field>

        <mat-form-field class="select-type" fxFlex="30" *ngIf="validationTypes.length > 1">
          <mat-label>Select validation type from dropdown</mat-label>
          <mat-select data-test="validationType" placeholder="Select validation type from dropdown"
            [disabled]="updateMode" formControlName="validationType">
            <mat-option *ngFor="let type of validationTypes" [value]="type">
              {{type}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </section>

      <div formArrayName="ruleForms" *ngFor="let item of validationRuleForms.controls; let i = index;">
        <mat-card appearance="outlined" [formGroupName]="i">
          <mat-card-header>
            <mat-card-title>
              <h4>Rule: {{item.get('ruleName').value}}</h4>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <section>
              <mat-form-field class="select-type">
                <mat-label>Lower Limit</mat-label>
                <input matInput data-test="lowerLimit" type="number" placeholder="Lower Limit"
                  [prevent-keys]="[13 , 16 , 18 , 20, '-', 'e']" formControlName="lowerLimit">
                <mat-error *ngIf="item.get('lowerLimit').invalid">Please enter a valid number</mat-error>

              </mat-form-field>

              <mat-form-field class="select-type">
                <mat-label>Upper Limit</mat-label>
                <input matInput data-test="upperLimit" type="number" placeholder="Upper Limit"
                  [prevent-keys]="[13 , 16 , 18 , 20, '-', 'e']" formControlName="upperLimit">
                <mat-error *ngIf="item.get('upperLimit').invalid">
                  Please enter a valid number</mat-error>

              </mat-form-field>
            </section>
            <section layout="row">
              <span class="toggle-button">Toggle
                Button Applied</span>
              <mat-slide-toggle data-test="hasBooleanFlag" formControlName="hasBooleanFlag" color="primary">
              </mat-slide-toggle>
              <span class="boolean-value" *ngIf="item.get('hasBooleanFlag').value">User Value Specified</span>
              <mat-checkbox class="check-box" formControlName="booleanFlag" *ngIf="item.get('hasBooleanFlag').value">
              </mat-checkbox>
            </section>
            <section>
              <mat-form-field class="status">
                <mat-label>Select status from dropdown</mat-label>
                <mat-select data-test="status" placeholder="Select status from dropdown" formControlName="status"
                  required>
                  <mat-option *ngFor="let status of Status.values()" [value]="Status[status]">
                    {{status}}
                  </mat-option>
                </mat-select>
                <!-- <mat-error *ngIf="status.invalid">Status is required</mat-error> -->
              </mat-form-field>
            </section>
          </mat-card-content>
        </mat-card>
      </div>
    </form>
    <mat-dialog-actions class="justify-items-end button-row">
      <button mat-button mat-raised-button color="primary" [disabled]="disableSaveButton()"
        (click)="save()">{{updateMode?'Update':'Save'}}</button>
      <button mat-button mat-raised-button color="secondary" (click)="onCloseDialog(false)">Cancel</button>
    </mat-dialog-actions>
  </mat-dialog-content>
</div>