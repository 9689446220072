<mat-card appearance="outlined" fxLayout="column" fxLayout.xs="column" class="policy-containner">
  <mat-card-header>
    <mat-card-title><strong>{{editable ? "Review Policy Premium(s)" : "Original Policy Premium(s)"}}</strong></mat-card-title>
  </mat-card-header>
  <mat-card-content fxLayout="column">
    <div fxLayout="row">
      <mat-table fxLayout="column" fxLayoutAlign="space-between" fxFlex #table
        [dataSource]="editable ? policy?.levelOfCovers : policy?.originalLevelOfCovers">
        <!--- Note that these columns can be defined in any order. The actual rendered columns are set as a property on the row definition" -->
        <!-- Description Column -->
        <ng-container matColumnDef="lovDisplayName">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">{{element ? element.lovDisplayName : ''}}</mat-cell>
          <mat-footer-cell *matFooterCellDef><em class="total">Totals</em></mat-footer-cell>
        </ng-container>
        <!-- Broker Net Column -->
        <ng-container matColumnDef="brokerNet">
          <mat-header-cell *matHeaderCellDef> Net Premium </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span>
              {{element ? moneytary(editable ? element.customBrokerNet : element.brokerNet) : ''}}
            </span>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef><span class="total">{{totalNet}}</span></mat-footer-cell>
        </ng-container>
        <!-- IPT Column -->
        <ng-container matColumnDef="iptRate">
          <mat-header-cell *matHeaderCellDef> IPT </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span>
              {{element ? moneytary(editable ? element.customIptAmount : element.iptAmount) : ''}}
            </span>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef><span class="total">{{totalIPT}}</span></mat-footer-cell>
        </ng-container>
        <!-- Gross Column -->
        <ng-container matColumnDef="gross">
          <mat-header-cell class="right-align" *matHeaderCellDef> Gross Premium </mat-header-cell>
          <mat-cell *matCellDef="let element"
          [class]="editable ? (element && element.isValidGrossPremium)  ? 'right-align' : 'right-align gross-red' : 'right-align'">
            <span>
              {{element ? moneytary(editable ? element.customClientGrossPremium : element.clientGrossPremium) : ''}}
            </span>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef><span class="total">{{totalGross}}</span></mat-footer-cell>
        </ng-container>

        <!--Net Maximum Retail Rate Column -->
        <ng-container matColumnDef="maximumRetailRate">
          <mat-header-cell class="right-align" *matHeaderCellDef>Net Maximum Retail Rate </mat-header-cell>
          <mat-cell *matCellDef="let element"
            [class]="(element && element.isValidGrossPremium)  ? 'right-align' : 'right-align gross-red'">
            <span> {{element && element.formattedMaximumRetailRate ?
              moneytary(element.formattedMaximumRetailRate):'£1,000.00'}}</span>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action" *ngIf="editable">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button [disabled]="isDisableUpdate" *ngIf="!isIE" mat-button class="full-width btn-action-loc" id="btn-edit-loc" matTooltip="Edit">
              <div [satPopoverAnchor]="popover" (click)="editable ? popover.open() : ''">
                <mat-icon svgIcon="pencil"></mat-icon>
              </div>
              <sat-popover #popover hasBackdrop xAlign="start" yAlign="start" (closed)="update(element, $event)">
                <inline-edit [value]="element ? element.formattedClientGrossPremium : ''" [title]="editTitle"
                [maximumRetailRate]="element?element.getMaximumRetailRate:0" >
                </inline-edit>
              </sat-popover>
            </button>
            <button [disabled]="isDisableUpdate" *ngIf="isIE" #editButtonRef mat-button class="full-width btn-action-loc" matTooltip="Edit"
              (click)="openEditPremiumPopup(element, $event)">
              <mat-icon svgIcon="pencil"></mat-icon>
            </button>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="editable ? editableDisplayedColumns : displayedColumns">
        </mat-header-row>
        <mat-row matRipple *matRowDef="let row; columns: editable ? editableDisplayedColumns : displayedColumns;">
        </mat-row>
        <ng-container>
          <mat-footer-row *matFooterRowDef="editable ? editableDisplayedColumns : displayedColumns">
          </mat-footer-row>
        </ng-container>
      </mat-table>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between end">
      <mat-form-field>
        <mat-label>Certificate Number</mat-label>
        <input placeholder="Certificate Number" [value]="policy.certificateNumber" matInput disabled>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Policy Inception Date</mat-label>
        <input placeholder="Policy Inception Date" [value]="isExpired ? renewalDate : startDate" matInput disabled>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Policy Expiry Date</mat-label>
        <input placeholder="Policy Expiry Date" [value]="isExpired ? renewalExpiredDate : expiredDate" matInput disabled>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
