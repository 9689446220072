<mat-toolbar class="mat-elevation-z6" fxLayout="row" fxLayoutAlign="start center" color="primary" >
  <button mat-button disableRipple="true" (click)="onMenuButtonClick()" data-test="hamburger-menu">
    <mat-icon svgIcon="menu"></mat-icon>
  </button>

  <mat-nav-list fxLayout="row" fxLayoutAlign="start center" class="horizontal">
    <a mat-list-item  [routerLink]="['/app']" data-test="home-icon">
      <img class="align-self-center" src="../../../../assets/img/Home_logo_white.png" height="40" />
    </a>
  </mat-nav-list>

  <span>Admin</span>
  <div fxFlex="400" fxLayoutAlign="space-around start" class="search-header" *ngIf="displaySearch">
  </div>
  <span class="welcome-message">{{userName}}</span>
  <mat-nav-list fxLayout="row" fxLayoutAlign="start center" class="horizontal">
    <a mat-list-item matTooltip="View User details and Logout" (click)="openUserDetails()">
      <mat-icon class="align-self-center" svgIcon="account-circle"></mat-icon>
    </a>
  </mat-nav-list>
</mat-toolbar>
