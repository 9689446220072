<mat-card appearance="outlined" fxLayout="column" fxLayout.xs="column" class="policy-containner">
    <mat-card-header>
        <mat-card-title><strong>Update Policy</strong></mat-card-title>
        <mat-card-subtitle>Update Policy here.</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content fxFlex="50" fxFlex.lt-sm="100">
        <ng-container>
          <div [formGroup]="searchForm" class="search-group">
              <mat-form-field class="search-field">
                <mat-label>Enter policy number</mat-label>
                <input  [required]="true" formControlName="searchString" matInput placeholder="Enter policy number" (keyup.enter)="search()">
                <button class="close-icon" *ngIf="!searchStringIsEmpty"  (click)="reset()"><mat-icon>clear</mat-icon></button>
                <mat-error *ngIf="searchStringControl.errors?.required || searchStringControl.errors?.whitespace">Policy Number is required</mat-error>
                <mat-error *ngIf="searchStringControl.value && searchStringControl.errors?.specialCharacter">
                  {{searchStringControl.errors?.specialCharacter?.message}}</mat-error>
              </mat-form-field>
              <button mat-button mat-raised-button color="primary" [disabled]="this.searchForm.pristine || this.searchForm.invalid" (click)="search()">
                Search
              </button>
          </div>
        </ng-container>
        <mat-divider *ngIf="isSearched"></mat-divider>
        <app-policy-details #policyDetails [policyData]="policyUpdate" [isExpired]="isExpired" [searched]="isSearched" (clearData)="reset()" (save)="update($event)"
        [isDisableUpdate]="isDisableUpdate" [errorMessage]="errorMessage"></app-policy-details>
    </mat-card-content>
</mat-card>
