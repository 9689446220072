<div class="current-pricing">
  <h3>Current Pricing</h3>
  <table mat-table [dataSource]="branchPricingMode ? currentSelectedBranchPricings : currentSelectedPricings"
  *ngIf="currentSelectedPricings.length>0 || currentSelectedBranchPricings.length>0">
    <ng-container matColumnDef="priceType">
      <th mat-header-cell *matHeaderCellDef>Price Type</th>
      <td mat-cell *matCellDef="let row">
        {{branchPricingMode? (row?.isCustom?'Custom':'Standard') : (row?.priceType?row?.priceType:'Standard')}}
      </td>
    </ng-container>

    <ng-container matColumnDef="brokerRate">
      <th mat-header-cell *matHeaderCellDef>Broker Rate</th>
      <td mat-cell *matCellDef="let row">{{ row?.brokerRate | currency:'GBP'}}</td>
    </ng-container>

    <ng-container matColumnDef="brokerRateIncludeIPT">
      <th mat-header-cell *matHeaderCellDef>Broker Rate Inc IPT</th>
      <td mat-cell *matCellDef="let row">{{row?.brokerRateIncludeIPT | currency:'GBP'}}</td>
    </ng-container>

    <ng-container matColumnDef="minimumSaleRate">
      <th mat-header-cell *matHeaderCellDef>Minimum Sale Rate</th>
      <td mat-cell *matCellDef="let row">{{row?.minimumSaleRate | currency:'GBP'}}</td>
    </ng-container>

    <ng-container matColumnDef="maximumRetailRate">
      <th mat-header-cell *matHeaderCellDef>Net Maximum Retail Rate</th>
      <td mat-cell *matCellDef="let row">{{row?.maximumRetailRate | currency:'GBP'}}</td>
    </ng-container>

    <ng-container matColumnDef="underwriterRate">
      <th mat-header-cell *matHeaderCellDef>Underwriter Rate</th>
      <td mat-cell *matCellDef="let row">{{row?.underwriterRate | currency:'GBP'}}</td>
    </ng-container>

    <ng-container matColumnDef="intermediaryCommission">
      <th mat-header-cell *matHeaderCellDef>Intermediary Commission</th>
      <td mat-cell *matCellDef="let row">{{row?.intermediaryCommission | currency:'GBP'}}</td>
    </ng-container>

    <ng-container matColumnDef="effectiveDateFrom">
      <th mat-header-cell *matHeaderCellDef>Effective Date From</th>
      <td mat-cell *matCellDef="let row">{{row?.effectiveDateFrom | date: 'dd/MM/yyyy'}}</td>
    </ng-container>

    <ng-container matColumnDef="effectiveDateTo">
      <th mat-header-cell *matHeaderCellDef>Effective Date To</th>
      <td mat-cell *matCellDef="let row">{{row?.effectiveDateTo | date: 'dd/MM/yyyy'}}</td>
    </ng-container>

    <ng-container matColumnDef="changedByUserName">
      <th mat-header-cell *matHeaderCellDef>User</th>
      <td mat-cell *matCellDef="let row">{{row?.changedByUserName}}</td>
    </ng-container>

    <ng-container matColumnDef="changedAt">
      <th mat-header-cell *matHeaderCellDef>Amended</th>
      <td mat-cell *matCellDef="let row">{{row?.changedAt | date: 'dd/MM/yyyy HH:mm'}}</td>
    </ng-container>

    <ng-container matColumnDef="isActive">
      <th mat-header-cell *matHeaderCellDef>Active</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row?.isActive" svgIcon="check" class="colour-success"></mat-icon>
        <mat-icon *ngIf="!row?.isActive" svgIcon="close" class="colour-danger"></mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div *ngIf="currentSelectedPricings.length === 0 && currentSelectedBranchPricings.length === 0 && doneLoadCurrentPricing">
    <h4>There is no current valid pricing!</h4>
  </div>
</div>

<div class="history-pricing">
  <h3>Pricing History</h3>
  <div [formGroup]="searchForm" class="search-group">
    <label id="include-inactive-items" class="label-include"><span><strong>Include Inactive
          Items:</strong></span>
    </label>
    <mat-radio-group formControlName="isIncludedInactive">
      <mat-radio-button data-test="noRadio" value="false" [checked]="isIncludedInactive.value === false">No
      </mat-radio-button>
      <mat-radio-button data-test="yesRadio" value="true" [checked]="isIncludedInactive.value === true">Yes
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="pricing-table">
    <table mat-table [dataSource]="branchPricingMode ? historyBranchPricings : historyPricings" matSort
      matSortActive="effectiveDateFrom" matSortDirection="desc" matSortDisableClear >

      <ng-container matColumnDef="priceType">
        <th mat-header-cell *matHeaderCellDef>Price Type</th>
        <td mat-cell *matCellDef="let row">
          {{branchPricingMode? (row.isCustom?'Custom':'Standard') : (row.priceType?row.priceType:'Standard')}}
        </td>
      </ng-container>

      <ng-container matColumnDef="brokerRate">
        <th mat-header-cell *matHeaderCellDef>Broker Rate</th>
        <td mat-cell *matCellDef="let row">{{row.brokerRate | currency:'GBP'}}</td>
      </ng-container>

      <ng-container matColumnDef="brokerRateIncludeIPT">
        <th mat-header-cell *matHeaderCellDef>Broker Rate Inc IPT</th>
        <td mat-cell *matCellDef="let row">{{row.brokerRateIncludeIPT | currency:'GBP'}}</td>
      </ng-container>

      <ng-container matColumnDef="minimumSaleRate">
        <th mat-header-cell *matHeaderCellDef>Minimum Sale Rate</th>
        <td mat-cell *matCellDef="let row">{{row.minimumSaleRate | currency:'GBP'}}</td>
      </ng-container>

      <ng-container matColumnDef="maximumRetailRate">
        <th mat-header-cell *matHeaderCellDef>Net Maximum Retail Rate</th>
        <td mat-cell *matCellDef="let row">{{row.maximumRetailRate | currency:'GBP'}}</td>
      </ng-container>

      <ng-container matColumnDef="underwriterRate">
        <th mat-header-cell *matHeaderCellDef>Underwriter Rate</th>
        <td mat-cell *matCellDef="let row">{{row.underwriterRate | currency:'GBP'}}</td>
      </ng-container>


      <ng-container matColumnDef="intermediaryCommission">
        <th mat-header-cell *matHeaderCellDef>Intermediary Commission</th>
        <td mat-cell *matCellDef="let row">{{row.intermediaryCommission | currency:'GBP'}}</td>
      </ng-container>

      <ng-container matColumnDef="effectiveDateFrom">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Effective Date From</th>
        <td mat-cell *matCellDef="let row">{{row.effectiveDateFrom | date: 'dd/MM/yyyy'}}</td>
      </ng-container>

      <ng-container matColumnDef="effectiveDateTo">
        <th mat-header-cell *matHeaderCellDef>Effective Date To</th>
        <td mat-cell *matCellDef="let row">{{row.effectiveDateTo | date: 'dd/MM/yyyy'}}</td>
      </ng-container>

      <ng-container matColumnDef="changedByUserName">
        <th mat-header-cell *matHeaderCellDef>User</th>
        <td mat-cell *matCellDef="let row">{{row.changedByUserName}}</td>
      </ng-container>

      <ng-container matColumnDef="changedAt">
        <th mat-header-cell *matHeaderCellDef>Amended</th>
        <td mat-cell *matCellDef="let row">{{row.changedAt | date: 'dd/MM/yyyy HH:mm'}}</td>
      </ng-container>

      <ng-container matColumnDef="isActive">
        <th mat-header-cell *matHeaderCellDef>Active</th>
        <td mat-cell *matCellDef="let row">
          <ng-container *ngIf="currentPricing; else noCurrent">
            <mat-icon *ngIf="row.isActive && currentPricing.id != row.id" svgIcon="check" class="colour-success"></mat-icon>
            <div *ngIf="row.isActive &&  currentPricing.id == row.id">
              <mat-icon svgIcon="check" class="colour-success"></mat-icon>
              <div class="small-txt">Current</div>
            </div>
            <mat-icon *ngIf="!row.isActive" svgIcon="close" class="colour-danger"></mat-icon>
          </ng-container>
          <ng-template #noCurrent>
            <mat-icon *ngIf="row.isActive" svgIcon="check" class="colour-success"></mat-icon>
            <mat-icon *ngIf="!row.isActive" svgIcon="close" class="colour-danger"></mat-icon>
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedHistoryColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedHistoryColumns;"
        [id]="row.id"
        [ngClass]="{selected: (row && updatePricing && updatePricing.id === row.id)}" (click)="onRowClick(row)" matTooltip="Click to view and amend pricing record">
      </tr>
    </table>
  </div>
</div>
