import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Product } from '../models/product';
import { Observable } from 'rxjs';
import { ApiEndPoints } from '../shared/config/api-end-points';
import { map } from 'rxjs/operators';
import { PagingConstant, FORMAT_ONLY_DATE } from 'src/app/shared/constants/constants';
import * as moment from 'moment';
import { DocumentModel, DocumentType } from '../models/document';
import * as _ from 'lodash';
import { PaginationResponse } from '../models/pagination-response';

@Injectable({
  providedIn: "root",
})
export class ProductService {
  constructor(private http: HttpClient, private api: ApiEndPoints) {}

  getProducts(
    isIncludedInactive: boolean,
    sortField = "",
    sortDirection = "asc",
    pageIndex = PagingConstant.pageIndex,
    pageSize = PagingConstant.pageSize
  ): Observable<PaginationResponse<Product>> {
    return this.http
      .get<PaginationResponse<Product>>(
        this.api.getAllProductsPaging(
          isIncludedInactive,
          sortField,
          sortDirection,
          pageIndex,
          pageSize
        )
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getActiveDocuments(documents: DocumentModel[]) {
    let activeDocuements: DocumentModel[] = [];
    //At a glance
    const atAGlance = this.getActiveDocumentByType(
      documents,
      DocumentType.AtAGlance
    );
    if (atAGlance !== null) {
      activeDocuements.push(atAGlance);
    }

    //IPID
    const ipid = this.getActiveDocumentByType(documents, DocumentType.IPID);
    if (ipid !== null) {
      activeDocuements.push(ipid);
    }

    //Master Certificate
    const masterCertificate = this.getActiveDocumentByType(
      documents,
      DocumentType.MasterCertificate
    );
    if (masterCertificate !== null) {
      activeDocuements.push(masterCertificate);
    }

    //Schedule
    const schedule = this.getActiveDocumentByType(
      documents,
      DocumentType.Schedule
    );
    if (schedule !== null) {
      activeDocuements.push(schedule);
    }

    //FVA
    const fva = this.getActiveDocumentByType(
      documents,
      DocumentType.FVA
    );
    if (fva !== null) {
      activeDocuements.push(fva);
    }

    return activeDocuements;
  }

  getActiveDocumentByType(
    documents: DocumentModel[],
    documentType: DocumentType
  ) {
    const activeDocuments = _.filter(documents, (x) => {
      return (
        x.documentType === documentType &&
        x.isActive &&
        moment(x.effectiveDateFrom).isBefore(moment(new Date())) &&
        moment(x.effectiveDateTo).isAfter(moment(new Date()))
      );
    });

    let orderByUploadedDate = _.orderBy(
      activeDocuments,
      ["createdOn"],
      ["desc"]
    );
    if (orderByUploadedDate.length > 0) {
      return orderByUploadedDate[0];
    }

    return null;
  }
}
