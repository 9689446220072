import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, AbstractControl, FormGroup, ValidatorFn, Validator, FormArray } from '@angular/forms';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { Insurer } from 'src/app/models/insurer';
import { SubProduct } from 'src/app/models/sub-product';
import { DEFAULT_EFFECTIVE_TO_DATE, FORMAT_ONLY_DATE, LeiConfig, ProductConstant, TitleMessages } from 'src/app/shared/constants/constants';
import { Status } from 'src/app/models/enums/status.enum';
import * as moment from 'moment';
import * as _ from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { noWhitespace } from 'src/app/validators/no-whitespace.validator';
import { ReferenceData } from 'src/app/models/reference-data';

@Component({
  selector: 'app-insurer-popup',
  templateUrl: './insurer-popup.component.html',
  styleUrls: ['./insurer-popup.component.scss']
})

export class InsurerPopupComponent implements OnInit, AfterViewInit {

  defaultEffectiveTo = moment(new Date(DEFAULT_EFFECTIVE_TO_DATE));
  updateInsurer: Insurer;
  updateMode = false;

  insurerForms: FormArray;
  form: FormGroup;

  Status = Status;
  subProducts: SubProduct[] = [];
  providers: ReferenceData[] = [];

  enterdEffectiveDateValid: boolean = true;
  isMultipleInsurers: boolean = false;

  isEffectiveFromDisabled: boolean = false;

  get minEffectiveFromDate() {
    return this.isEffectiveFromDisabled ? undefined : new Date();
  }

  get minEffectiveToDate() {
    const today = new Date();
    return today;
  }

  constructor(
    private readonly _httpService: HttpBaseService,
    private readonly _api: ApiEndPoints,
    public dialogService: DialogService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<InsurerPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.createForm();
    this.insurerForms = this.form.get('insurerForms') as FormArray;

    this.updateInsurer = data.Insurer;
    if (this.updateInsurer) {
      this.updateMode = true;
    } else {
      this.insurerForms.push(this.createInsurerForm())
    }
  }

  addInsurerForm() {
    if (!this.updateMode) {
      this.insurerForms = this.form.get('insurerForms') as FormArray;
      this.insurerForms.push(this.createInsurerForm());
    }
  }

  createInsurerForm(insurer: Insurer = undefined): FormGroup {
    return this.formBuilder.group({
      id: [insurer ? insurer.id : ''],
      rowVersion: [insurer ? insurer.rowVersion : ''],
      value: [insurer ? insurer.value : '', [Validators.required, noWhitespace]],
      provider:[insurer ? insurer.provider : ''],
      address: [insurer ? insurer.address : '', [Validators.required, noWhitespace]],
    });
  }

  removeInsurerForm(control) {
    if (!this.updateMode) {
      this.insurerForms.removeAt(
        this.insurerForms.controls.findIndex(group => group === control)
      );
    }
  }

  async ngOnInit() {
    await this.loadSubProductData();
    await this.loadProvidersData();

    if (this.updateMode) {
      this.initDataForUpdate(this.updateInsurer);

      const subP = _.find(this.subProducts, (x) => { return x.id === this.updateInsurer.subProductId });
      this.isMultipleInsurers = subP && subP.product && subP.product.shortName === ProductConstant.taxiClub;
      if (this.isMultipleInsurers) {
        //find insurers with same effective date from and isActive
        let insurersSameDate = _.filter(subP.insurers,
          (x) => { return (x.masterCertificate == this.updateInsurer.masterCertificate
                        && x.isActive == this.updateInsurer.isActive
                        && moment(x.effectiveDateFrom).isSame(moment(this.updateInsurer.effectiveDateFrom), 'day')
                        && moment(x.effectiveDateTo).isSame(moment(this.updateInsurer.effectiveDateTo), 'day')) });

        insurersSameDate = _.orderBy(insurersSameDate, "value");
        insurersSameDate.forEach(insurer => {
          const insurerForm = this.createInsurerForm(insurer);
          insurerForm.get('value').disable();
          insurerForm.get('address').disable();
          this.insurerForms.push(insurerForm);
        });
      } else {
        const insurerForm = this.createInsurerForm(this.updateInsurer);
        insurerForm.get('value').disable();
        insurerForm.get('address').disable();
        this.insurerForms.push(insurerForm);
      }
    }
  }

  checkMultipleInsurers(subProductId: string) {
    const subP = _.find(this.subProducts, (x) => { return x.id === subProductId });
    return subP && subP.product && subP.product.shortName === ProductConstant.taxiClub;
  }

  ngAfterViewInit(): void {
    if (!this.updateMode) {
      this.subProduct.valueChanges.subscribe(v => {
        this.isMultipleInsurers = this.checkMultipleInsurers(v);
        this.clearInsurerForms();
      });
    }


    this.effectiveDateFrom.valueChanges
    .pipe(
      debounceTime(LeiConfig.delayBeforeSearchingWithAutoSearchFields),
      distinctUntilChanged()
    )
    .subscribe(v => {
      if (v) {
        this.validateEffectiveDateFrom(v);
      }
    });

    this.effectiveDateTo.valueChanges
    .pipe(
      debounceTime(2500),
      distinctUntilChanged()
    )
    .subscribe(v => {
      if (v) {
        this.validateEffectiveDateTo(v);
      }
    });
  }

  createForm() {
    this.form = this.formBuilder.group({
      subProduct: ['', [Validators.required]],
      insurerForms: this.formBuilder.array([]),
      masterCertificate: ['', [Validators.required, noWhitespace]],
      schemeCode: [],
      status: [Status.Active, [Validators.required]],
      effectiveDateFrom: ['', [Validators.required]],
      effectiveDateTo: ['']
    });
  }

  async loadSubProductData() {
    this.subProducts = await this._httpService.getDataAsync<SubProduct[]>(this._api.getAllSubProducts());
  }

  async loadProvidersData() {
    this.providers = await this._httpService.getDataAsync<ReferenceData[]>(this._api.getProvidersForInsurer());
    let emptyOption: ReferenceData = {
      description: "N/A",
      value: "",
      key: ""
    };
    this.providers.unshift(emptyOption);
  }

  get subProduct() {
    return this.form.get('subProduct') as AbstractControl;
  }

  get schemeCode() {
    return this.form.get('schemeCode') as AbstractControl;
  }

  get masterCertificate() {
    return this.form.get('masterCertificate') as AbstractControl;
  }

  get effectiveDateFrom() {
    return this.form.get('effectiveDateFrom') as AbstractControl;
  }

  get effectiveDateTo() {
    return this.form.get('effectiveDateTo') as AbstractControl;
  }

  public get status() {
    return this.form.get('status') as AbstractControl;
  }

  public get subInsurerForms() {
    return this.form.get('insurerForms') as FormArray;
  }

  initDataForUpdate(insurer: Insurer) {
    if (insurer) {
      this.subProduct.setValue(insurer.subProductId);
      this.subProduct.disable();
      this.masterCertificate.setValue(insurer.masterCertificate);
      this.masterCertificate.disable();
      this.schemeCode.setValue(insurer.schemeCode);
      // this.schemeCode.disable();
      this.effectiveDateFrom.setValue(insurer.effectiveDateFrom);
      // this.effectiveDateFrom.disable();
      this.effectiveDateTo.setValue(insurer.effectiveDateTo);
      // this.effectiveDateTo.disable();
      this.status.setValue(insurer.isActive ? Status.Active : Status.Inactive);

      const today = new Date();
      let tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);

      this.isEffectiveFromDisabled = moment(tomorrow).isAfter(insurer.effectiveDateFrom, 'day') || moment(today).isAfter(insurer.effectiveDateTo, 'day');
    }
  }

  async validateEffectiveDateFrom(enterdDate: any) {
    if (this.subProduct.invalid) {
      this.dialogService.openErrorDialog({ title: TitleMessages.warningTitle , message: 'Please select sub product first!' });
      this.subProduct.markAsTouched();
      return;
    }

    if (this.effectiveDateTo.value) {
      if (moment(enterdDate, FORMAT_ONLY_DATE) > moment(this.effectiveDateTo.value, FORMAT_ONLY_DATE)) {
        this.dialogService.openErrorDialog({title: TitleMessages.warningTitle , message: 'The effective to date entered is less than the effective from date entered. Please check and re-enter the date!' });
        this.effectiveDateFrom.setValue('', { emitEvent: false });
        return;
      }
    }
    return;
  }

  async validateEffectiveDateTo(enterdDate: any) {
    let errorMessage = '';
    if (this.effectiveDateFrom.value) {
      if (moment(this.effectiveDateFrom.value, FORMAT_ONLY_DATE) > moment(enterdDate, FORMAT_ONLY_DATE)) {
        errorMessage = 'The effective to date entered is less than the effective from date entered. Please check and re-enter the date!';
        this.effectiveDateTo.setValue('', { emitEvent: false });
        this.enterdEffectiveDateValid = false;
      } else {
        this.enterdEffectiveDateValid = true;
      }
    }
    else {
      errorMessage = 'Please enter the effective from date first!';
      this.effectiveDateFrom.setValue('', { emitEvent: false });
    }

    if (!this.enterdEffectiveDateValid) {
      this.dialogService.openErrorDialog({ title: TitleMessages.warningTitle , message: errorMessage });
    }
  }

  async onSaveAndClose() {
    await this.save(true);
  }

  async save(closeDialog: boolean) {
    if (this.form.valid && this.enterdEffectiveDateValid) {
      let insurers: Insurer[] = [];
      for (let i = 0; i < this.insurerForms.length; i++) {
        const insurerForm = this.insurerForms.controls[i];
        let insurer: Insurer = {
          id: this.updateMode ? insurerForm.get('id').value : undefined,
          rowVersion: this.updateMode ? insurerForm.get('rowVersion').value : undefined,
          subProductId: this.subProduct.value,
          value: insurerForm.get('value').value,
          provider:insurerForm.get('provider').value,
          address: insurerForm.get('address').value,
          masterCertificate: this.masterCertificate.value,
          schemeCode: this.schemeCode.value,
          effectiveDateFrom: moment(this.effectiveDateFrom.value).format(FORMAT_ONLY_DATE),
          effectiveDateTo: moment(this.effectiveDateTo.value ? this.effectiveDateTo.value : this.defaultEffectiveTo).format(FORMAT_ONLY_DATE),
          isActive: this.status.value,
          changedByUserName: undefined,
          subProduct: undefined
        };

        insurers.push(insurer);
      }
      try {
        let message: string = '';
        if (!this.updateMode) {
          await this._httpService.postDataAsync<string[]>(this._api.createInsurers(), insurers);
          message = closeDialog ? 'The insurer has been saved successfully' : 'The insurer has been saved successfully. It is ready for adding new one.';
        }
        else {
          await this._httpService.putDataAsync<Insurer[]>(this._api.updateInsurers(), insurers);
          message = 'The insurer has been updated successfully';
        }

        this.dialogService.openSuccessDialogConfirm({ title: TitleMessages.successTitle, message: message }).subscribe(async result => {
          if (result) {
            if (closeDialog) {
              this.dialogRef.close(true);
            } else {
              this.clearData();
            }
          }
        })
      }
      finally {
      }
    }
    else {
      return;
    }
  }

  cancel() {
    if (this.form.dirty) {
      this.dialogService.openConfirmationDialog({ title: TitleMessages.confirmationTitle, message: 'Are you sure you want to continue. Your changes will be lost?' }).subscribe(result => {
        if (result) {
          this.dialogRef.close(true);
        }
      });
    }
    else {
      this.dialogRef.close(true);
    }
  }

  clearData() {
    this.subProduct.setValue('', { emitEvent: false });
    this.subProduct.markAsPristine();
    this.subProduct.markAsUntouched();
    this.masterCertificate.setValue('', { emitEvent: false });
    this.masterCertificate.markAsPristine();
    this.masterCertificate.markAsUntouched();
    this.schemeCode.setValue('', { emitEvent: false });
    this.schemeCode.markAsPristine();
    this.schemeCode.markAsUntouched();
    this.effectiveDateFrom.setValue('', { emitEvent: false });
    this.effectiveDateFrom.markAsPristine();
    this.effectiveDateFrom.markAsUntouched();
    this.effectiveDateTo.setValue('', { emitEvent: false });
    this.effectiveDateTo.markAsPristine();
    this.effectiveDateTo.markAsUntouched();

    this.clearInsurerForms();
  }

  clearInsurerForms() {
    const length = this.insurerForms.length;
    for (let i = 0; i < length; i++) {
      const insurerForm = this.insurerForms.controls[i];
      this.removeInsurerForm(insurerForm);
    }
    this.addInsurerForm();
  }
}

