
export class Utility {
    public static toTitleCase(words: string) {
        let splitStr = words.split(' ');
        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }

    public static isNullOrWhiteSpace(value) {
        if (value == null) { return true; }
        return value.replace(/^\s+|\s+$/g, '').length === 0;
    }

    public static encodeSearchString(searchString: string) {
        if (this.isNullOrWhiteSpace(searchString)) {
            return 'null';
        }
        return encodeURIComponent(searchString);
    }

    static formatPhoneNumber(phoneNumber: string) {
        phoneNumber = phoneNumber.replace('/[^\d]/g', "").replace(' ', "");

        const regex1 = '^([0][17][0-9]{3}) ?([0-9]{5,6})$';
        let match = phoneNumber.match(regex1);
        if (match) {
            return match[1] + ' ' + match[2];
        }

        const regex2 = '^([0][238][0-9]{2}) ?([0-9]{3}) ?([0-9]{4})$';
        match = phoneNumber.match(regex2);
        if (match) {
            return match[1] + ' ' + match[2] + ' ' + match[3];
        }

        return phoneNumber;
    }

    static moneytary(number: any) {
        return `£${parseFloat(number).toLocaleString('en-GB', { minimumFractionDigits: 2 })}`;
    }

    public static addDays(date: Date, days: number) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }

    public static removeTags(str) {
      if ((str === null) || (str === ''))
        return false;
      else
        str = str.toString();

      // Regular expression to identify HTML tags in
      // the input string. Replacing the identified
      // HTML tag with a null string.
      return str.replace(/(<([^>]+)>)/ig, '');
    }

    public static getMonthName(dateString) {
        // Create a Date object from the string
        const date = new Date(dateString);
      
        // Get the month index (0-indexed)
        const monthIndex = date.getMonth();
      
        // Create an array of month names
        const months = [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];
      
        // Return the month name based on the index
        return months[monthIndex];
      }
}

export function formatCurrencyWithCommas(x) {
    x = parseFloat(x).toFixed(2);
    if (isNaN(x)) {
        return '';
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

