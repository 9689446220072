<div class="level-of-cover-popup">
    <h3 class="title">Level of cover update required</h3>
    <strong>The current Level of cover is not valid for the vehicle age. </strong>
    <span *ngIf="vehicleRegistration"><strong>(Registration number: {{vehicleRegistration}})</strong></span>
    <mat-dialog-content class="mat-dialog-content"  fxLayout="column">
        <mat-table fxLayout="column" fxLayoutAlign="space-between" fxFlex #table [dataSource]="originalLevelOfCovers">
            <ng-container matColumnDef="lovDisplayName">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let element">{{element ? element.lovDisplayName : ''}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="brokerNet">
              <mat-header-cell *matHeaderCellDef> Net Premium </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span>
                  {{element ? moneytary(element.brokerNet) : ''}}
                </span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="iptRate">
              <mat-header-cell *matHeaderCellDef> IPT </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span>
                  {{element ? moneytary(element.iptAmount) : ''}}
                </span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="gross">
              <mat-header-cell class="right-align" *matHeaderCellDef> Gross Premium </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span>
                  {{element ? moneytary(element.clientGrossPremium) : ''}}
                </span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element"></mat-cell>
              </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns">
            </mat-header-row>
            <mat-row matRipple *matRowDef="let row; columns: displayedColumns;">
            </mat-row>
          </mat-table>

          <hr>
          <strong>Please select from the available level of covers below:</strong>
          <mat-table *ngIf="newLevelOfCovers.length; else noValidLevelOfCover" fxLayout="column" fxLayoutAlign="space-between" fxFlex #table [dataSource]="newLevelOfCovers">
            <ng-container matColumnDef="lovDisplayName">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let element">{{element ? element.lovDisplayName : ''}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="brokerNet">
              <mat-header-cell *matHeaderCellDef> Net Premium </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span>
                  {{element ? moneytary(element.brokerNet) : ''}}
                </span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="iptRate">
              <mat-header-cell *matHeaderCellDef> IPT </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span>
                  {{element ? moneytary(element.iptAmount) : ''}}
                </span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="gross">
              <mat-header-cell class="right-align" *matHeaderCellDef> Gross Premium </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span>
                  {{element ? moneytary(element.clientGrossPremium) : ''}}
                </span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-icon *ngIf="(element && selectedLoC && element.id == selectedLoC.id)"
                    class="right-align" svgIcon="check">
                </mat-icon>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row matRipple *matRowDef="let row; columns: displayedColumns"
            [ngClass]="{selected: (row && selectedLoC && selectedLoC.id === row.id)}"
            (click)="onSelectLevelOfCover(row)"></mat-row>
          </mat-table>
          <ng-template #noValidLevelOfCover>
            <p>There is no level of cover for your selection</p>
          </ng-template>
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
      <button mat-button mat-raised-button color="primary" [disabled]="selectedLoC === null" (click)="onSave()">Ok</button>
      <button mat-button mat-raised-button color="default" (click)="onCancel()">Cancel</button>
    </mat-dialog-actions>
  </div>
