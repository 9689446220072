import { Component, OnInit, Inject, AfterViewInit, Input } from '@angular/core';
import { FormBuilder, Validators, AbstractControl, FormGroup, ValidatorFn, Validator } from '@angular/forms';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { SubProduct } from 'src/app/models/sub-product';
import { Product } from 'src/app/models/product';
import { DocumentModel, DocumentType } from 'src/app/models/document';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ProductService } from 'src/app/services/product.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-sub-product-document',
  templateUrl: './sub-product-document.component.html',
  styleUrls: ['./sub-product-document.component.scss']
})

export class SubProductDocumentComponent implements OnInit {

  product: Product;
  subProduct: SubProduct;
  form: FormGroup;

  displayedColumns: string[] = ['name', 'fileName', 'dateUploaded'];

  documents: DocumentModel[] = [];
  documentTypes = [];
  documentType = DocumentType;

  constructor(
    private readonly _httpService: HttpBaseService,
    private readonly _api: ApiEndPoints,
    public dialogService: DialogService,
    private productService: ProductService,
    public dialogRef: MatDialogRef<SubProductDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.subProduct = data.subProduct;
    this.product = data.product;

    this.documents = this.productService.getActiveDocuments(this.subProduct.documents);

  }

  async ngOnInit() {
    await this.loadDocumentTypes();
  }

  async loadDocumentTypes() {
    this.documentTypes = await this._httpService.getDataAsync(this._api.getDocumentTypes());
    this.documentTypes = _.filter(this.documentTypes, (x) => { return (x.key !== DocumentType.All) });
  }


  close() {
    this.dialogRef.close();
  }

  getDocumentTypeName(key: string) {
    const type = _.find(this.documentTypes, (x) => { return (x.key == key) });
    return type ? type.name : '';
  }

  openDocument(document: DocumentModel) {
    if (document.documentType !== DocumentType.Schedule) {
      this._httpService.openPdf(this._api.downloadFile(document.url), document.url, () => {
      }, () => {
        this.dialogService.openErrorDialog({ title: 'Broker Admin', message: 'File not found!' });
      });
    }
  }
}

