<mat-card>
  <mat-card-header>
    <mat-card-title><strong>Insurers</strong></mat-card-title>
    <mat-card-subtitle>Maintain Insurers and Master Certificates here.</mat-card-subtitle>

  </mat-card-header>
  <mat-card-content>
    <div>
      <button mat-button class="btn-table-row btn-create" (click)="createInsurer()" matTooltip="Create new Insurer">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div>
      <section [formGroup]="subProductForm" class="filter" fxLayout="row">
        <mat-form-field fxFlex="20">
          <mat-label>Sub Product</mat-label>
          <mat-select data-test="subProduct" placeholder="Sub Product" formControlName="subProduct">
            <mat-option value='' [matTooltip]="'All'">All</mat-option>
            <mat-option *ngFor="let subProduct of subProducts" [value]="subProduct.id" [matTooltip]="subProduct.name">
              {{subProduct.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </section>
    </div>
    <div [formGroup]="searchInsurerForm" fxLayout="column" fxLayoutAlign="end end">
      <div fxLayout="row" fxLayoutAlign="end center">
        <div class="section">
          <app-shared-search (onSearch)="search()" (onValueChange)="onValueChange($event)"
            [placeholderText]="'Search by Sub Product, Insurer or Master Certificate'">
          </app-shared-search>
        </div>
      </div>
      <div class="include-inactive">
        <label id="include-inactive-items" class="label-include"><span><strong>Include Inactive
              Items:</strong></span>
        </label>
        <mat-radio-group formControlName="isIncludedInactive">
          <mat-radio-button data-test="noRadio" value="false" [checked]="isIncludedInactive.value === false">No
          </mat-radio-button>
          <mat-radio-button data-test="yesRadio" value="true" [checked]="isIncludedInactive.value === true">Yes
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <table mat-table [dataSource]="dataSource" matSort matSortActive="effectiveDateFrom" matSortDirection="desc"
      matSortDisableClear>

      <ng-container matColumnDef="providerName">
        <th mat-header-cell *matHeaderCellDef>Provider</th>
        <td mat-cell *matCellDef="let row">
          {{row.providerName?row.providerName:''}}
        </td>
      </ng-container>

      <ng-container matColumnDef="subProductName">
        <th mat-header-cell *matHeaderCellDef>Sub Product</th>
        <td mat-cell *matCellDef="let row">{{row.subProductName?row.subProductName:''}}</td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef>Insurer</th>
        <td mat-cell *matCellDef="let row">{{row.value}}</td>
      </ng-container>

      <ng-container matColumnDef="masterCertificate">
        <th mat-header-cell *matHeaderCellDef>Master Certificate</th>
        <td mat-cell *matCellDef="let row">{{row.masterCertificate}}</td>
      </ng-container>

      <ng-container matColumnDef="schemeCode">
        <th mat-header-cell *matHeaderCellDef>Scheme Code</th>
        <td mat-cell *matCellDef="let row">{{row.schemeCode}}</td>
      </ng-container>

      <ng-container matColumnDef="effectiveDateFrom">
        <th mat-header-cell *matHeaderCellDef>Effective Date From</th>
        <td mat-cell *matCellDef="let row">{{row.effectiveDateFrom | date: 'dd/MM/yyyy' }}</td>
      </ng-container>

      <ng-container matColumnDef="effectiveDateTo">
        <th mat-header-cell *matHeaderCellDef>Effective Date To</th>
        <td mat-cell *matCellDef="let row">{{row.effectiveDateTo | date: 'dd/MM/yyyy' }}</td>
      </ng-container>

      <ng-container matColumnDef="updatedBy">
        <th mat-header-cell *matHeaderCellDef>User</th>
        <td mat-cell *matCellDef="let row">{{row.changedByUserName }}</td>
      </ng-container>

      <ng-container matColumnDef="isActive">
        <th mat-header-cell *matHeaderCellDef>Active</th>
        <td mat-cell *matCellDef="let row">
          <mat-icon *ngIf="row.isActive" svgIcon="check" class="colour-success"></mat-icon>
          <mat-icon *ngIf="!row.isActive" svgIcon="close" class="colour-danger"></mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Edit</th>
        <td mat-cell *matCellDef="let row">
          <button mat-button class="btn-action" (click)="editInsurer(row)" matTooltip="Edit">
            <mat-icon svgIcon="pencil"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div fxFex fxLayout="row" fxLayoutAlign="space-between">
      <span class="not-found-text">{{notFoundMessage}}</span>

      <mat-paginator [length]="dataSource.totalRecords" [pageIndex]="pagingConstant.pageIndex"
        [pageSize]="pagingConstant.pageSizeLarger" [pageSizeOptions]="pagingConstant.pageSizeOptionsLarger"
        showFirstLastButtons>
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>