export enum ExternalBrokerType {
    Ssp = 1,
    OpenGi = 2,
    Acturis = 3,
    Howden = 4
}

export namespace ExternalBrokerType {
  export function values() {
      return Object.keys(ExternalBrokerType).filter((type) => isNaN(<any>type) && type !== 'values');
  }
}
