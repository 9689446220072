<div [formGroup]="form" *ngIf="this.searched" class="search-result-container">
  <div class="policy-holder">
    <strong class="header" *ngIf="this.policyData !== null">Policy Holder Details</strong>
    <div class="content">
      <ng-container *ngIf="this.policyData !== null; else noResult">
        <ng-container>
          <ng-container *ngIf="this.policyData.company; else individual">
            <mat-form-field>
              <mat-label> Company </mat-label>
              <input placeholder="Company" formControlName="company" matInput aria-disabled="true">
            </mat-form-field>
          </ng-container>
          <ng-template #individual>
            <mat-form-field>
              <mat-label> Title </mat-label>
              <input placeholder="Title" formControlName="title" matInput aria-disabled="true">
            </mat-form-field>
            <mat-form-field>
              <mat-label> First Name </mat-label>
              <input placeholder="First Name" formControlName="firstName" matInput aria-disabled="true">
            </mat-form-field>
            <mat-form-field>
              <mat-label> Surname </mat-label>
              <input placeholder="Surname" formControlName="surname" matInput aria-disabled="true">
            </mat-form-field>
          </ng-template>
        </ng-container>
      </ng-container>
      <ng-template #noResult>
        <mat-error>No results found.</mat-error>
      </ng-template>
    </div>
  </div>
  <div class="policy-details" *ngIf="this.policyData !== null">
    <strong class="header">Policy Details</strong>
    <div class="content">
      <div>
        <p class="bold-text">{{this.policyData.productName}}</p>
        <app-level-of-cover-table [editable]="false" [policy]="this.policyData"></app-level-of-cover-table>
        <app-level-of-cover-table *ngIf="isExpired && !isCancelled" [editable]="true" [isExpired]="true"
          [policy]="this.policyData" (grossChange)='grossChange($event)'
          [isDisableUpdate]="isDisableUpdate"></app-level-of-cover-table>
      </div>
      <mat-error class="error-update" *ngIf="this.policyData !== null && isCancelled">Policy status is not valid for
        updating a policy (cancelled on {{cancellationDate}})</mat-error>
      <mat-error class="error-update" *ngIf="isDisableUpdate && this.policyData !== null" [innerHTML]='"Unfortunately, this product is no longer available" + errorMessage'>
      </mat-error>
      <div fxLayout="row" fxLayoutAlign="space-between end">
        <div fxFlex></div>
        <mat-form-field *ngIf="policyData !== null && !isExpired && !isCancelled" class="not-margin">
          <mat-label>Policy Inception Date</mat-label>
          <input matInput [disabled]="isCancelled" [matDatepicker]="picker" [min]="minDate" [max]="maxDate" required
            formControlName="inceptionDate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker startView="month"></mat-datepicker>
          <mat-hint>dd/mm/yyyy</mat-hint>
          <mat-error *ngIf="inceptionDateControl.hasError('matDatepickerMin')">Incorrect min date is
            entered.</mat-error>
          <mat-error *ngIf="inceptionDateControl.hasError('matDatepickerMax')">Incorrect max date is
            entered.</mat-error>
          <mat-error *ngIf="form.controls.inceptionDate.invalid && form.controls.inceptionDate.touched">
            <span *ngIf="form.controls.inceptionDate.errors?.required &&
                      !form.controls.inceptionDate.errors?.matDatepickerParse">Inception date is required</span>
            <span *ngIf="form.controls.inceptionDate.errors?.matDatepickerParse">Invalid date is entered</span>
          </mat-error>
        </mat-form-field>
        <div fxFlex fxLayout="row" fxLayoutAlign="end end" class="actions">
          <button mat-button mat-raised-button color="primary"
            [disabled]="invalidGross || isDisableUpdate  || isCancelled || (!isExpired && (this.form.pristine || this.form.invalid))"
            (click)="saveClick()">
            Update
          </button>
          <button mat-button mat-raised-button *ngIf="this.policyData !== null" (click)="cancelClick()">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
