<div class="broker-group-popup">
  <h3 class="title">Broker Group</h3>
  <span class="title-desciption">Maintain existing Brokers together under a Broker Group here.</span>
  <mat-dialog-content class="mat-dialog-content">
    <div class="broker-group-detail">
      <div class="broker-detail">
        <section fxLayout="row">
          <div fxFlex="30">
            <app-search-broker-group #searchbrokergroup
              (selectedBrokerGroupOutput)="setSelectedBrokerGroup($event)"></app-search-broker-group>
            <form [formGroup]="groupForm">
              <section>
                <mat-form-field *ngIf="!disableAdd()">
                  <mat-label>Add new broker group</mat-label>
                  <input required matInput data-test="value" placeholder="Add new broker group"
                    formControlName="newGroup" maxlength="256" TitleCase>
                  <mat-error *ngIf="newGroup.errors?.required || newGroup.errors?.whitespace">Broker Group is
                    required</mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="disableAdd()">
                  <mat-label>Add new broker group</mat-label>
                  <input matInput data-test="value" placeholder="Add new broker group" maxlength="256"
                    [readonly]="disableAdd()">
                </mat-form-field>


                <button mat-button mat-raised-button color="primary" class="btn-create" (click)="createBrokerGroup()"
                  [disabled]="newGroup.invalid">
                  <mat-icon>add</mat-icon>
                </button>
              </section>

              <section>
                <mat-form-field>
                  <mat-label>Broker Group name</mat-label>
                  <input matInput data-test="value" placeholder="Broker Group name" formControlName="existingGroup"
                    maxlength="256" readonly [matTooltip]="existingGroup.value">
                </mat-form-field>
                <button mat-button mat-raised-button color="primary" (click)="reset()"
                  [disabled]="!existingGroup.value">Reset</button>
              </section>

            </form>

          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <div fxFlex="70" class="broker-area">
            <app-search-broker #searchbroker (selectedBrokerOutput)="setSelectedBroker($event)"></app-search-broker>

            <div class="selected-brokers">
              <table mat-table [dataSource]="dataSource">

                <ng-container matColumnDef="originalId">
                  <th mat-header-cell *matHeaderCellDef>Original Id</th>
                  <td mat-cell *matCellDef="let row">{{(row.originalBrokerId)}}</td>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Name</th>
                  <td mat-cell *matCellDef="let row">{{row.name}}</td>
                </ng-container>

                <ng-container matColumnDef="address">
                  <th mat-header-cell *matHeaderCellDef>Address</th>
                  <td mat-cell *matCellDef="let row">{{getCombileAddress(row)}}</td>
                </ng-container>

                <ng-container matColumnDef="remove">
                  <th mat-header-cell *matHeaderCellDef>Remove</th>
                  <td mat-cell *matCellDef="let row">
                    <button mat-button class="btn-action" (click)="removeBroker(row)" matTooltip="Remove broker">
                      <mat-icon svgIcon="delete"></mat-icon>
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </div>
        </section>

      </div>
    </div>
    <mat-dialog-actions class="justify-items-end button-row">
      <button mat-button mat-raised-button color="primary" [disabled]="disableSave()" (click)="onSave()">Save</button>
      <button mat-button mat-raised-button color="secondary" (click)="onCloseDialog(false)">Cancel</button>
    </mat-dialog-actions>
  </mat-dialog-content>