<div class="sub-product-popup">
  <h3 class="title">Sub Product Document Control</h3>
  <span class="title-desciption">Manage documents here.</span>
  <mat-dialog-content class="mat-dialog-content">
    <form [formGroup]="form" #formDirective="ngForm">
      <section fxLayout="row">
        <mat-form-field fxFlex="30" class="margin-right">
          <mat-label>Product Name</mat-label>
          <input matInput data-test="productName" placeholder="Product Name" formControlName="productName"
            [readonly]="true">
        </mat-form-field>
        <mat-form-field fxFlex="70" class="margin-right">
          <mat-label>Sub Product Name</mat-label>
          <input matInput data-test="subProductName" placeholder="Sub Product Name" formControlName="subProductName"
            [readonly]="true">
        </mat-form-field>
      </section>
      <section fxLayout="row">
        <mat-form-field fxFlex="17" class="margin-right">
          <mat-label>Document Type</mat-label>
          <mat-select data-test="documentType" required placeholder="Document Type" formControlName="type">
            <mat-option *ngFor="let type of documentTypes" [value]="type.key">
              {{type.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="type.invalid">Type is required</mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="17" class="margin-right">
          <mat-label>Select status from dropdown</mat-label>
          <mat-select data-test="status" placeholder="Select status from dropdown" formControlName="status" required>
            <mat-option *ngFor="let status of Status.values()" [value]="Status[status]">
              {{status}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="status.invalid">Status is required</mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="17" class="margin-right">
          <mat-label>Effective Date From</mat-label>
          <input data-test="effectiveDateFrom" [matDatepicker]="effectiveDateFromComponent" [required]="true"
            matNativeControl spellcheck="false" matInput placeholder="Effective Date From"
            formControlName="effectiveDateFrom">
          <mat-datepicker-toggle matSuffix [for]="effectiveDateFromComponent">
          </mat-datepicker-toggle>
          <mat-datepicker #effectiveDateFromComponent></mat-datepicker>
          <mat-hint>dd/mm/yyyy</mat-hint>
          <mat-error *ngIf="!effectiveDateFrom.errors?.matDatepickerParse && effectiveDateFrom.hasError('required')">Effective date from is required</mat-error>
          <mat-error *ngIf="effectiveDateFrom.errors?.matDatepickerParse">Please enter valid date</mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="17" class="margin-right">
          <mat-label>Effective Date To</mat-label>
          <input data-test="effectiveDateTo" [matDatepicker]="effectiveDateToComponent" matNativeControl
            spellcheck="false" matInput placeholder="Effective Date To" formControlName="effectiveDateTo">
          <mat-datepicker-toggle matSuffix [for]="effectiveDateToComponent">
          </mat-datepicker-toggle>
          <mat-datepicker #effectiveDateToComponent></mat-datepicker>
          <mat-hint>dd/mm/yyyy</mat-hint>
          <mat-error *ngIf="effectiveDateTo.invalid">Please enter valid date</mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="17">
          <mat-label>Document File</mat-label>
          <input required data-test="filePath" placeholder="Document File" matInput type="search" readonly #filePath
            formControlName="filePath">
          <mat-icon matSuffix svgIcon="magnify" (click)="!updateMode && file.click()" class="search-button"></mat-icon>
          <mat-error>Please upload valid document</mat-error>
        </mat-form-field>
        <input #file type="file" [hidden]="true" accept=".html, .cshtml,application/pdf" (change)="onSelectFile($event.target.files)">

        <button mat-button color="primary" (click)="reset()" class="reset-button">Reset</button>
      </section>
    </form>
    <div class="selected-document" *ngIf="currentSelectedDocuments.length>0">
      <table mat-table [dataSource]="currentSelectedDocuments">

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Document Type</th>
          <td mat-cell *matCellDef="let row">{{getDocumentTypeName(row.documentType)}}</td>
        </ng-container>

        <ng-container matColumnDef="fileName">
          <th mat-header-cell *matHeaderCellDef>File Name</th>
          <td mat-cell *matCellDef="let row">{{row.fileName}}</td>
        </ng-container>

        <ng-container matColumnDef="effectiveDateFrom">
          <th mat-header-cell *matHeaderCellDef>Effective Date From</th>
          <td mat-cell *matCellDef="let row">{{row.effectiveDateFrom | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
        </ng-container>


        <ng-container matColumnDef="effectiveDateTo">
          <th mat-header-cell *matHeaderCellDef>Effective Date To</th>
          <td mat-cell *matCellDef="let row">{{row.effectiveDateTo | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef><span class="edit-header">Delete</span></th>
          <td mat-cell *matCellDef="let row">
            <button mat-button (click)="deleteDocument(row)" matTooltip="Delete">
              <mat-icon svgIcon="delete-forever"></mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

    <mat-dialog-actions class="justify-items-end button-row">
      <button mat-button mat-raised-button color="primary"
        [disabled]="form.invalid && currentSelectedDocuments.length === 0" (click)="onSave(false)">Save</button>
        <button mat-button mat-raised-button color="primary"
        [disabled]="form.invalid && currentSelectedDocuments.length === 0" (click)="onSave(true)">Save & Close</button>
      <button mat-button mat-raised-button color="secondary" (click)="onCloseDialog(true)">Cancel</button>
    </mat-dialog-actions>

  </mat-dialog-content>

  <div *ngIf="liveDocuments.length > 0">
    <h3>Live Documents</h3>

    <div>
      <table mat-table [dataSource]="liveDocuments">

        <ng-container matColumnDef="documentType">
          <th mat-header-cell *matHeaderCellDef>Document Type</th>
          <td mat-cell *matCellDef="let row">{{getDocumentTypeName(row.documentType)}}</td>
        </ng-container>

        <ng-container matColumnDef="fileName">
          <th mat-header-cell *matHeaderCellDef>File Name</th>
          <td mat-cell *matCellDef="let row">
            <a [class]="row.documentType !== documentType.Schedule? 'document-file' : ''" (click)="openDocument(row)">{{row.fileName}}</a>
          </td>
        </ng-container>

        <ng-container matColumnDef="changedByUserName">
          <th mat-header-cell *matHeaderCellDef>User</th>
          <td mat-cell *matCellDef="let row">{{row.changedByUserName}}</td>
        </ng-container>

        <ng-container matColumnDef="createdOn">
          <th mat-header-cell *matHeaderCellDef>Date Uploaded</th>
          <td mat-cell *matCellDef="let row">{{row.createdOn | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
        </ng-container>

        <ng-container matColumnDef="effectiveDateFrom">
          <th mat-header-cell *matHeaderCellDef>Effective Date From</th>
          <td mat-cell *matCellDef="let row">{{row.effectiveDateFrom | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
        </ng-container>


        <ng-container matColumnDef="effectiveDateTo">
          <th mat-header-cell *matHeaderCellDef>Effective Date To</th>
          <td mat-cell *matCellDef="let row">{{row.effectiveDateTo | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
        </ng-container>

        <ng-container matColumnDef="isActive">
          <th mat-header-cell *matHeaderCellDef>Active</th>
          <td mat-cell *matCellDef="let row">
            <mat-icon *ngIf="row.isActive" svgIcon="check" class="colour-success"></mat-icon>
            <mat-icon *ngIf="!row.isActive" svgIcon="close" class="colour-danger"></mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedHistoryColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedHistoryColumns;">
        </tr>
      </table>
    </div>
  </div>
  <div>
    <h3>Document History</h3>

    <div [className]="currentSelectedDocuments.length>0 || historyDocuments.length>6 ? 'history-document' : ''">
      <table mat-table [dataSource]="historyDocuments" matSort matSortActive="effectiveDateFrom" matSortDirection="desc"
      matSortDisableClear>

        <ng-container matColumnDef="documentType">
          <th mat-header-cell *matHeaderCellDef>Document Type</th>
          <td mat-cell *matCellDef="let row">{{getDocumentTypeName(row.documentType)}}</td>
        </ng-container>

        <ng-container matColumnDef="fileName">
          <th mat-header-cell *matHeaderCellDef>File Name</th>
          <td mat-cell *matCellDef="let row"><a [class]="row.documentType !== documentType.Schedule? 'document-file' : ''" (click)="openDocument(row)">{{row.fileName}}</a></td>
        </ng-container>

        <ng-container matColumnDef="changedByUserName">
          <th mat-header-cell *matHeaderCellDef>User</th>
          <td mat-cell *matCellDef="let row">{{row.changedByUserName}}</td>
        </ng-container>

        <ng-container matColumnDef="createdOn">
          <th mat-header-cell *matHeaderCellDef>Date Uploaded</th>
          <td mat-cell *matCellDef="let row">{{row.createdOn | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
        </ng-container>

        <ng-container matColumnDef="effectiveDateFrom">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Effective Date From</th>
          <td mat-cell *matCellDef="let row">{{row.effectiveDateFrom | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
        </ng-container>


        <ng-container matColumnDef="effectiveDateTo">
          <th mat-header-cell *matHeaderCellDef>Effective Date To</th>
          <td mat-cell *matCellDef="let row">{{row.effectiveDateTo | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
        </ng-container>

        <ng-container matColumnDef="isActive">
          <th mat-header-cell *matHeaderCellDef>Active</th>
          <td mat-cell *matCellDef="let row">
            <mat-icon *ngIf="row.isActive" svgIcon="check" class="colour-success"></mat-icon>
            <mat-icon *ngIf="!row.isActive" svgIcon="close" class="colour-danger"></mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedHistoryColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedHistoryColumns;"
          [ngClass]="{selected: (row && updateDocument && updateDocument.id === row.id)}" (click)="onRowClick(row)">
        </tr>
      </table>
    </div>
  </div>
</div>
