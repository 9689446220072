import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { SatPopoverModule } from '@ncstate/sat-popover';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';

import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DomSanitizer } from '@angular/platform-browser';

import {MatTabsModule} from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatRippleModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatExpansionModule } from '@angular/material/expansion';
import { CKEditorModule } from 'ng2-ckeditor';
import { MY_FORMATS } from './shared/constants/constants';

import { AuthGuardService } from './services/auth-guard.service';
import { UserProfileService } from './services/userprofile.service';

import { AppComponent } from './app.component';
import { NavigationComponent } from './shared/components/navigation/navigation.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { IptConfirmPopupComponent } from './shared/components/ipt-confirm-popup/ipt-confirm-popup.component';
import { IeInlineEditComponent } from './shared/components/ie-inline-edit/ie-inline-edit.component';
import { InlineEditComponent } from './shared/components/inline-edit/inline-edit.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { SideNavComponent } from './shared/components/side-nav/side-nav.component';
import { MenuListItemComponent } from './shared/components/menu-list-item/menu-list-item.component';
import { NavService } from './shared/components/side-nav/nav.service';
import { BrokerComponent } from './components/broker/broker.component';
import { BranchesComponent } from './components/branches/branches.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './components/home/home.component';
import { ProductConfigurationComponent } from './components/product-configuration/product-configuration.component';
import { ProductPricingComponent } from './components/product-pricing/product-pricing.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ReportingComponent } from './components/reporting/reporting.component';
import { AmendmentsComponent } from './components/amendments/amendments.component';
import { LevelsOfCoverConfigurationComponent } from './components/levelsofcover-configuration/levelsofcover-configuration.component';

import { AlertComponent } from './components/alert/alert.component';
import { AuditTrailComponent } from './components/audit-trail/audit-trail.component';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { StandardHeaderInterceptor } from './shared/interceptors/standard-header.interceptor';
import { AlertHistoryComponent } from './components/alert-history/alert-history.component';
import { DialogComponent } from './shared/components/dialog/dialog.component';
import { EmailTemplateComponent } from './components/settings/system-setting/email-template/email-template.component';
import { PricingComponent } from './components/product-pricing/pricing/pricing.component';
import { LevelOfCoverValidationComponent } from './components/level-of-cover-validation/level-of-cover-validation.component';
import { LevelOfCoverValidationRuleComponent } from './components/level-of-cover-validation/level-of-cover-validation-rule/level-of-cover-validation-rule.component';
import { StationaryMaintenanceComponent } from './components/stationary-maintenance/stationary-maintenance.component';
import { StationaryMaintenancePopupComponent } from './components/stationary-maintenance/stationary-maintenance-popup/stationary-maintenance-popup.component';
import { NumberOnlyDirective } from './directives/number-only-directive';
import { DisabledAutocompleteInputDirective } from './directives/disabled.autocomplete.input.directive';
import { CanAccessDirective } from './directives/can-access.directive';
import { SystemSettingComponent } from './components/settings/system-setting/system-setting.component';
import { InsurerComponent } from './components/insurer/insurer.component';
import { SubProductComponent } from './components/sub-product/sub-product.component';
import { SubProductPopupComponent } from './components/sub-product/sub-product-popup/sub-product-popup.component';
import { InsurerPopupComponent } from './components/insurer/insurer-popup/insurer-popup.component';
import { AuditTrailDetailComponent } from './components/audit-trail/audit-trail-detail/audit-trail-detail.component';
import { ProductInformationComponent } from './components/product-configuration/product-information/product-information.component';
import { BrokerDetailsComponent } from './components/broker/broker-details/broker-details.component';
import { ContactDetailComponent } from './components/contact-detail/contact-detail.component';
import { SubProductDocumentControlComponent } from './components/sub-product/sub-product-document-control/sub-product-document-control.component';
import { SubProductDocumentComponent } from './components/sub-product/sub-product-document/sub-product-document.component';
import { ErrorHandlerComponent } from './shared/error-handler/error-handler.component';
import { BrokerGroupComponent } from './components/broker-group/broker-group.component';
import { BrokerGroupPopupComponent } from './components/broker-group/broker-group-popup/broker-group-popup.component';
import { SearchBrokerComponent } from './components/broker-group/search-broker/search-broker.component';
import { BranchPopupComponent } from './components/branches/branch-popup/branch-popup.component';
import { LevelOfCoverPopupComponent } from './components/levelsofcover-configuration/level-of-cover-popup/level-of-cover-popup.component';
import { TwoDigitDecimalNumberDirective } from './directives/two-digit-decimal-number.directive';
import { BranchProductDetailComponent } from './components/branch-product-detail/branch-product-detail.component';
import { BranchPricingComponent } from './components/product-pricing/branch-pricing.component';
import { PricingHistoryComponent } from './components/product-pricing/pricing-history/pricing-history.component';
import { IptRateSettingComponent } from './components/settings/system-setting/ipt-rate-setting/ipt-rate-setting.component';
import { EmailTemplatesComponent } from './components/settings/system-setting/email-templates/email-templates.component';
import { MasterSettingComponent } from './components/settings/system-setting/master-setting/master-setting.component';
import { UserComponent } from './components/user/user.component';
import { SearchBrokerBranchComponent } from './components/user/search-broker-branch/search-broker-branch.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { UserDetailPopupComponent } from './components/user-detail-popup/user-detail-popup.component';
import { PreventKeysDirective } from './directives/prevent-keys.directive';
import { MatInputCommifiedDirective } from './directives/mat-input-conmified.directive';
import { SearchBrokerGroupComponent } from './components/broker-group/search-broker-group/search-broker-group.component';
import { UpdatePolicyComponent } from './components/policy/update-policy.component';
import { PolicyDetailsComponent } from './components/policy/details/policy-details.component';
import { LevelOfCoverTableComponent } from './components/policy/details/level-of-cover-table.component';
import { LevelOfCoverRechoiceComponent } from './components/policy/details/loc-rechoice-popup/loc-rechoice.component';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { AccessDeniedComponent } from './shared/components/access-denied/access-denied.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { UnSavedChangesGuard } from './shared/can-deactivate/un-saved-changes.guard';
import { TitleCaseDirective } from './directives/title-case.directive';
import { PreviousRouteService } from './shared/services/previous-route.service';
import { PhoneDirecive } from './directives/phone.directive';
import { SharedSearchComponent } from './shared/components/shared-search/shared-search.component';
import { AddressInfoComponent } from './shared/components/address-info/address-info.component';

import { MaxLengthMessage } from './shared/pipes/max-length-error-message.pipe';
import { SafeHtmlPipe } from './shared/pipes/sanitize-html.pipe';
import { UserAccessReportComponent } from './components/user-access-report/user-access-report.component';
import { CustomPricingReportComponent } from './components/custom-pricing-report/custom-pricing-report.component';
import { CustomPricingFormDetailComponent } from './components/custom-pricing-report/custom-pricing-report-form-detail/custom-pricing-report-form-detail.component';
import {MatChipsModule} from '@angular/material/chips';
import { ResendOptionPopupComponent } from './shared/components/resend-option-popup/resend-option-popup.component';
import { DeclarationsReportComponent } from './components/declarations-report/declarations-report.component';
import { ReportsHistoryComponent } from './components/reports-history/reports-history.component';
import { UploadReportComponent } from './components/reports-history/upload-report/upload-report.component';
import { AragDeclarationsReportComponent } from './components/declarations-report/arag-declarations-report/arag-declarations-report.component';
import { LpgDeclarationsReportComponent } from './components/declarations-report/lpg-declarations-report/lpg-declarations-report.component';
import { ClaimsMadeReportComponent } from './components/claims-made-report/claims-made-report.component';
import { MleiTableComponent } from './components/claims-made-report/mlei-table/mlei-table.component';
import { ExcessProtectionTableComponent } from './components/claims-made-report/excess-protection-table/excess-protection-table.component';
import { HomeEmergencyTableComponent } from './components/claims-made-report/home-emergency-table/home-emergency-table.component';
import { UnavailableComponent } from './shared/error-handler/unavailable.component';
import { AuthenticateComponent } from './shared/components/authenticate/authenticate.component';


export function storageFactory(): OAuthStorage {
  return sessionStorage;
}

const appInitializerFn = (configurationService: ConfigurationService) => {
  return async () => {
    const clientConfiguration = await configurationService.loadRuntimeConfig();
    
    configurationService.checkServices();
    
    await configurationService.loadConfig(clientConfiguration ? clientConfiguration.apiUrl : '');
  };
};

@NgModule({
  declarations: [
    // Directive
    NumberOnlyDirective,
    CanAccessDirective,
    PreventKeysDirective,
    TwoDigitDecimalNumberDirective,
    MatInputCommifiedDirective,
    DisabledAutocompleteInputDirective,
    TitleCaseDirective,
    PhoneDirecive,
    // Component
    AppComponent,
    EmailTemplateComponent,
    NavigationComponent,
    HeaderComponent,
    IptConfirmPopupComponent,
    IeInlineEditComponent,
    InlineEditComponent,
    FooterComponent,
    SideNavComponent,
    DialogComponent,
    MenuListItemComponent,
    BrokerComponent,
    BranchesComponent,
    HomeComponent,
    ProductConfigurationComponent,
    ProductPricingComponent,
    SettingsComponent,
    AlertComponent,
    AlertHistoryComponent,
    ReportingComponent,
    AmendmentsComponent,
    PricingComponent,
    LevelOfCoverValidationComponent,
    LevelOfCoverValidationRuleComponent,
    AuditTrailComponent,
    LevelsOfCoverConfigurationComponent,
    StationaryMaintenanceComponent,
    StationaryMaintenancePopupComponent,
    SystemSettingComponent,
    InsurerComponent,
    SubProductComponent,
    SubProductPopupComponent,
    InsurerPopupComponent,
    AuditTrailDetailComponent,
    ProductInformationComponent,
    BrokerDetailsComponent,
    ContactDetailComponent,
    SubProductDocumentControlComponent,
    SubProductDocumentComponent,
    ErrorHandlerComponent,
    BrokerGroupComponent,
    BrokerGroupPopupComponent,
    SearchBrokerComponent,
    BranchPopupComponent,
    LevelOfCoverPopupComponent,
    BranchProductDetailComponent,
    BranchPricingComponent,
    PricingHistoryComponent,
    IptRateSettingComponent,
    EmailTemplatesComponent,
    MasterSettingComponent,
    UserComponent,
    SearchBrokerBranchComponent,
    UserManagementComponent,
    UserDetailPopupComponent,
    SearchBrokerGroupComponent,
    UpdatePolicyComponent,
    PolicyDetailsComponent,
    LevelOfCoverTableComponent,
    LevelOfCoverRechoiceComponent,
    LoadingComponent,
    AccessDeniedComponent,
    AuthenticateComponent,
    SharedSearchComponent,
    AddressInfoComponent,
    CustomPricingReportComponent,
    CustomPricingFormDetailComponent,
    //Pipe
    MaxLengthMessage,
    SafeHtmlPipe,
    UserAccessReportComponent,
    ResendOptionPopupComponent,
    DeclarationsReportComponent,
    ReportsHistoryComponent,
    UploadReportComponent,
    AragDeclarationsReportComponent,
    LpgDeclarationsReportComponent,
    ClaimsMadeReportComponent,
    MleiTableComponent,
    ExcessProtectionTableComponent,
    HomeEmergencyTableComponent,
    UnavailableComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    MatChipsModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [],
        sendAccessToken: true
      }
    }),
    AppRoutingModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatTooltipModule,
    MatStepperModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatCardModule,
    MatDividerModule,
    MatTableModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatTabsModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatAutocompleteModule,
    MatExpansionModule,
    CKEditorModule,
    SatPopoverModule
  ],
  providers: [
    ConfigurationService,
    UnSavedChangesGuard,
    PreviousRouteService,
    MaxLengthMessage,
    SafeHtmlPipe,
    { provide: APP_INITIALIZER, useFactory: appInitializerFn, multi: true, deps: [ConfigurationService] },
    UserProfileService,
    AuthGuardService,
    NavService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StandardHeaderInterceptor,
      multi: true,
    },
    { provide: OAuthStorage, useFactory: storageFactory },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    CurrencyPipe
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private readonly matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/mdi.svg'));
  }
}
