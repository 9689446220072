import { Component, OnInit } from '@angular/core';
import { PolicyAuditReport, PolicyAudit, AuditReport, ChangeHistory } from 'src/app/models/policy-audit';
import { AppConstants } from 'src/app/shared/constants/constants';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AuditSearchResult } from 'src/app/models/audit-search-result';
import { SelectionModel } from '@angular/cdk/collections';
import { Utility } from 'src/app/utils/utility';
import { AuthService } from 'src/app/services/auth.service';
import { UserRoleAuditAssigning } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.scss']
})
export class AuditTrailComponent implements OnInit {

  policyAuditReport: PolicyAuditReport;
  policyAudit: PolicyAudit;
  appconstants = AppConstants;
  displayedColumns: string[] = ['changedBy', 'changedSummary', 'auditAt'];
  detailSelection = new SelectionModel<ChangeHistory>(false, []);
  Utility = Utility;

  isLoading = false;
  notfound = false;
  searchForm = this._formBuilder.group({
    certificateNumber: ['', [Validators.required]],
    entityName: ['', [Validators.required]]
  });
  // new stuff
  auditSearchResults: AuditSearchResult[];
  auditSearchResultDisplayedColumns: string[] = ['description'];
  auditReport: AuditReport;
  masterSelection = new SelectionModel<AuditSearchResult>(false, []);
  // constants
  auditEntities = [];
  searchTermPlaceholder = 'Enter search criteria';
  historySummary = '';
  userProfile: object;
  userRole: number;

  get entityName() {
    return this.searchForm.get('entityName') as AbstractControl;
  }

  get certificateNumber() {
    return this.searchForm.get('certificateNumber') as AbstractControl;
  }

  get headerSearchCol() {
    if (this.entityName) {
      return this.entityName.value + ' Information';
    }
    return '';
  }

  get searchStringIsEmpty() {
    return Utility.isNullOrWhiteSpace(this.certificateNumber.value);
  }
  constructor(private authService: AuthService,
    private readonly _formBuilder: FormBuilder, private httpService: HttpBaseService, private api: ApiEndPoints) {
    this.authService.isAuthenticated$.subscribe(authenticated => {
      if (authenticated) {
        this.authService.userInfo$.subscribe(user => {
          this.userProfile = user;
          if (this.userProfile) {
            this.userRole = this.userProfile['role'];
            this.filterAuditEntityBaseOnUserRole();
          }
        });
      }
    });
  }

  ngOnInit() {
    this.entityName.valueChanges.subscribe(
      item => {
        if (item) {
          switch (item) {
            case 'Broker':
            case 'BrokerBranch':
              this.searchTermPlaceholder = 'Enter search criteria: Id, Name, Addresses or Postcode';
              break;
            case 'Product':
            case 'SubProduct':
            case 'LevelOfCover':
              this.searchTermPlaceholder = 'Enter search criteria: Name';
              break;
            case 'Policy':
              this.searchTermPlaceholder = 'Enter search criteria: Policy Number/ Certificate Number';
              break;
            case 'Quote':
              this.searchTermPlaceholder = 'Enter search criteria: Quote Number';
              break;
            case 'User':
              this.searchTermPlaceholder = 'Enter search criteria: User Name';
              break;
            default:
              this.searchTermPlaceholder = 'Enter search criteria';
              break;
          }
          this.certificateNumber.setValue('', { emitEvent: false });
          this.auditSearchResults = [];
          this.auditReport = null;
          this.notfound = false;
        }
      });
  }

  filterAuditEntityBaseOnUserRole() {
    this.auditEntities = UserRoleAuditAssigning[this.userRole] || [];
  }

  async search() {
    this.isLoading = true;
    this.notfound = false;
    this.policyAuditReport = null;
    this.auditSearchResults = [];
    this.auditReport = null;
    this.httpService.getData<AuditSearchResult[]>(this.api.searchAuditEntity(this.entityName.value, this.certificateNumber.value?.trim()))
      .subscribe(report => {
        if (report == null || report.length < 1) {
          this.notfound = true;
        } else {
          this.auditSearchResults = report;
        }
      }, () => {
        this.notfound = true;
        this.isLoading = false;
      }, () => {
        this.isLoading = false;
      });
  }

  reset(){
    this.certificateNumber.setValue('');
  }

  onClick(row: PolicyAudit) {
    this.detailSelection.toggle(row);
    row.selected = !row.selected;
    this.policyAudit = row;
  }

  searchResultRowClick(row: AuditSearchResult) {
    this.masterSelection.toggle(row);
    this.historySummary = row.description;
    this.policyAudit = undefined;
    this.isLoading = true;
    this.httpService.getData<AuditReport>(this.api.getSpecificAuditHistory(row.id, row.entityName)).subscribe(
      report => {
        if (report == null) {
          this.notfound = true;
        }
        else {
          this.auditReport = report;
        }
      },
      () => {
        this.notfound = true;
        this.isLoading = false;
      }, () => {
        this.isLoading = false;
      }
    );
  }

}
