<form [formGroup]="optionForm">
    <div class="dialog-header">
        <h3 class="title">Would you like to get a copy registration email?</h3>
    </div>
    <mat-divider class="line-break"></mat-divider>
    <mat-dialog-content>

        <mat-radio-group aria-labelledby="radio-group-label" class="radio-group-options" formControlName="options">
            <mat-radio-button #option1 class="radio-button" [value]="0">
                No
            </mat-radio-button>
            <div>
                <mat-radio-button #option2 class="radio-button" [value]="1">
                    Yes
                </mat-radio-button>
                <mat-form-field *ngIf="isShowEmail" class="email">
                    <mat-label>Email Address</mat-label>
                    <input [required]="true" data-test="user-emailaddress-input" matInput placeholder="Email Address"
                        formControlName="emailAddress" lowercase (keyup.enter)="onCloseDialog()"/>
                    <mat-error *ngIf="emailAddress.hasError('required')">Email Address is required</mat-error>
                    <mat-error *ngIf="emailAddress.errors?.email || emailAddress.hasError('invalidEmail')">Email
                        Address is
                        invalid</mat-error>
                    <mat-error *ngIf="emailAddress.errors?.maxlength">Email Address can be max 250 characters
                        long</mat-error>
                </mat-form-field>
            </div>
        </mat-radio-group>

    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <button class="save-buton" mat-raised-button color="primary" type="button"
            (click)="onCloseDialog()" (keyup.enter)="onCloseDialog()">{{isShowEmail && this.emailAddress.valid ? "Send" : "Close"}}</button>
    </mat-dialog-actions>
</form>
