export interface Broker {
    id: string;
    name: string;
    originalBrokerId: number;
    postCode: string;
    address1: string;
    address2: string;
    address3: string;
    address4: string;
    address5: string;
    telephoneNumber: string;
    emailAddress: string;
    website: string;
    logo: string;
    isActive: boolean;
    isCreatingDefaultBranch: boolean,
    contacts: Contact[];
    rowVersion: any;
    brokerGroupId:string,
    branches: BrokerBranch[];

    openGiBrokerId: string;
    sspBrokerId: string;
    acturisBrokerId: string;
    howdenBrokerId: string;
}
export interface Contact {
    id: string;
    name: string;
    keyContact: string;
    jobTitle: string;
    phoneNumber: string;
    email: string;
    rowVersion: string;
}

export interface BrokerBranch {
    id: string;
    brokerName: string;
    rowVersion: any;
    contacts: Contact[];
    brokerId: string;
    originalBrokerId: number;
    originalBranchId: number;
    name: string;
    postcode: string;
    address1: string;
    address2: string;
    address3: string;
    address4: string;
    address5: string;
    telephoneNumber: string;
    emailAddress: string;
    website: string;
    isActive: boolean;

    openGiBrokerId: string;
    sspBrokerId: string;
    acturisBrokerId: string;
    howdenBrokerId: string;
}


export interface BranchInformation {
    id: string;
    rowVersion: any;
    originalBranchId: number;
    name: string;
    address1: string,
    address2: string,
    address3: string,
    address4: string,
    address5: string,
    postcode: string,
    telephoneNumber: string,
    emailAddress: string,
    website: string,
    isActive: boolean,
    brokerId: string;
    originalBrokerId: number;
    brokerName: string;
    brokerIsActive: boolean;
}
