<mat-card appearance="outlined" class="mat-elevation-z2">
  <mat-card-content class="ipt-rate-setting-content">
    <form [formGroup]="form" #formDirective="ngForm">
      <section fxLayout="row">
        <mat-form-field class="ipt-rate-value">
          <mat-label>IPT Rate</mat-label>
          <input matInput data-test="iptRateValue" placeholder="IPT Rate" formControlName="value"
          [prevent-keys]="[13 , 16 , 18 , 20, '-', '+' , 'e']" [required]="true" type="number">
          <mat-error *ngIf="value.hasError('required')"> IPT Rate value is required</mat-error>
          <mat-error *ngIf="value.invalid && !value.hasError('required') ">Invalid IPT Rate value</mat-error>
        </mat-form-field>

        <mat-form-field class="select-dropdown">
          <mat-label>Select status from dropdown</mat-label>
          <mat-select data-test="status" placeholder="Select status from dropdown" formControlName="status" required>
            <mat-option *ngFor="let status of Status.values()" [value]="Status[status]">
              {{status}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="status.invalid">Status is required</mat-error>
        </mat-form-field>

        <mat-form-field class="effective-date">
          <mat-label>Effective Date From</mat-label>
          <input data-test="effectiveDateFrom" [matDatepicker]="effectiveDateFromComponent" [required]="true"
            matNativeControl spellcheck="false" matInput placeholder="Effective Date From"
            formControlName="effectiveDateFrom">
          <mat-datepicker-toggle matSuffix [for]="effectiveDateFromComponent">
          </mat-datepicker-toggle>
          <mat-datepicker #effectiveDateFromComponent></mat-datepicker>
          <mat-hint>dd/mm/yyyy</mat-hint>

          <mat-error *ngIf="!effectiveDateFrom.errors?.matDatepickerParse && effectiveDateFrom.hasError('required')">Effective Date is required</mat-error>
          <mat-error *ngIf="effectiveDateFrom.hasError('matDatepickerMax') || effectiveDateFrom.hasError('matDatepickerMin')">The date is invalid.</mat-error>
          <mat-error *ngIf="effectiveDateFrom.errors?.matDatepickerParse || effectiveDateFrom.errors?.invalid">The date is invalid.</mat-error>
        </mat-form-field>

        <mat-form-field class="effective-date">
          <mat-label>Effective Date To</mat-label>
          <input data-test="effectiveDateTo" [matDatepicker]="effectiveDateToComponent" matNativeControl
            spellcheck="false" matInput placeholder="Effective Date To" formControlName="effectiveDateTo">
          <mat-datepicker-toggle matSuffix [for]="effectiveDateToComponent">
          </mat-datepicker-toggle>
          <mat-datepicker #effectiveDateToComponent></mat-datepicker>
          <mat-hint>dd/mm/yyyy</mat-hint>

          <mat-error *ngIf="effectiveDateTo.hasError('matDatepickerMax') || effectiveDateTo.hasError('matDatepickerMin')">The date is invalid.</mat-error>
          <mat-error *ngIf="effectiveDateTo.errors?.matDatepickerParse || effectiveDateTo.errors?.invalid">The date is invalid.</mat-error>
        </mat-form-field>
        <button mat-button color="primary" (click)="reset()" class="reset-button">Reset</button>
      </section>
    </form>
    <div class="selected-document" *ngIf="currentSelectedIptRates.length>0">
      <h3>Current IPT Rate</h3>
      <table mat-table [dataSource]="currentSelectedIptRates">

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef>IPT rate</th>
          <td mat-cell *matCellDef="let row">{{row?.value}}</td>
        </ng-container>

        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef>User</th>
          <td mat-cell *matCellDef="let row">{{row?.changedByUserName}}</td>
        </ng-container>

        <ng-container matColumnDef="dateUpdated">
          <th mat-header-cell *matHeaderCellDef>Date Updated</th>
          <td mat-cell *matCellDef="let row">{{row?.changedAt | date: 'dd/MM/yyyy HH:mm'}}</td>
        </ng-container>

        <ng-container matColumnDef="effectiveDateFrom">
          <th mat-header-cell *matHeaderCellDef>Effective Date From</th>
          <td mat-cell *matCellDef="let row">{{row?.effectiveDateFrom | date: 'dd/MM/yyyy'}}</td>
        </ng-container>

        <ng-container matColumnDef="effectiveDateTo">
          <th mat-header-cell *matHeaderCellDef>Effective Date To</th>
          <td mat-cell *matCellDef="let row">{{row?.effectiveDateTo | date: 'dd/MM/yyyy'}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

    <mat-dialog-actions class="justify-items-end button-row">
      <button mat-button mat-raised-button color="primary" (click)="onSave()" [disabled]="form.invalid || form.pristine">Save</button>
      <button mat-button mat-raised-button color="secondary" (click)="onCloseDialog(false)">Cancel</button>
    </mat-dialog-actions>

    <div>
      <h3>IPT Rate History</h3>
      <div [formGroup]="searchForm" class="search-group">
        <label id="include-inactive-items" class="label-include"><span><strong>Include Inactive
              Items:</strong></span>
        </label>
        <mat-radio-group formControlName="isIncludedInactive">
          <mat-radio-button data-test="noRadio" value="false" [checked]="isIncludedInactive.value === false">No
          </mat-radio-button>
          <mat-radio-button data-test="yesRadio" value="true" [checked]="isIncludedInactive.value === true">Yes
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div [className]="currentSelectedIptRates.length>0 || historyIptRates.length>6 ? 'history-document' : ''">
        <table mat-table [dataSource]="historyIptRates" matSort matSortActive="effectiveDateFrom" matSortDirection="desc"
          matSortDisableClear>

          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>IPT Rate</th>
            <td mat-cell *matCellDef="let row">{{row.value}}</td>
          </ng-container>

          <ng-container matColumnDef="changedByUserName">
            <th mat-header-cell *matHeaderCellDef>User</th>
            <td mat-cell *matCellDef="let row">{{row.changedByUserName}}</td>
          </ng-container>

          <ng-container matColumnDef="changedAt">
            <th mat-header-cell *matHeaderCellDef>Date Updated</th>
            <td mat-cell *matCellDef="let row">{{row.changedAt | date: 'dd/MM/yyyy HH:mm'}}</td>
          </ng-container>

          <ng-container matColumnDef="effectiveDateFrom">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Effective Date From</th>
            <td mat-cell *matCellDef="let row">{{row.effectiveDateFrom | date: 'dd/MM/yyyy'}}</td>
          </ng-container>


          <ng-container matColumnDef="effectiveDateTo">
            <th mat-header-cell *matHeaderCellDef>Effective Date To</th>
            <td mat-cell *matCellDef="let row">{{row.effectiveDateTo | date: 'dd/MM/yyyy'}}</td>
          </ng-container>

          <ng-container matColumnDef="isActive">
            <th mat-header-cell *matHeaderCellDef>Active</th>
            <td mat-cell *matCellDef="let row">
              <mat-icon *ngIf="row.isActive" svgIcon="check" class="colour-success"></mat-icon>
              <mat-icon *ngIf="!row.isActive" svgIcon="close" class="colour-danger"></mat-icon>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedHistoryColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedHistoryColumns;"
            [ngClass]="{selected: (row && updateIptRate && updateIptRate.id === row.id)}" (click)="onRowClick(row)">
          </tr>

        </table>
      </div>
    </div>
  </mat-card-content>
</mat-card>
